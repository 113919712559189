import React, { useState } from "react";
import Table from "../table/Table";
import { deviceMovementsHeaders } from "../../lib/constants/devices";

import MOVED_OUT from "@style-guide/assets/icons/arrow_right_up.svg";
import MOVED_IN from "@style-guide/assets/icons/arrow_right_down.svg";
import { BdrPictogram } from "@style-guide/web-components-react";

const DeviceMovements = ({ movements }) => {
  const [movedInPic, setMovedInPic] = useState();
  const [movedOutPic, setMovedOutPic] = useState();

  fetch(MOVED_IN)
    .then((r) => r.text())
    .then(setMovedInPic);

  fetch(MOVED_OUT)
    .then((r) => r.text())
    .then(setMovedOutPic);

  const toMovedComponent = ({ moved_in, moved_out }) => (
    <>
      {moved_in && (
        <BdrPictogram pictogram={movedInPic} alternativeText="Wareneingang" />
      )}
      {moved_out && (
        <BdrPictogram pictogram={movedOutPic} alternativeText="Warenausgang" />
      )}
      {moved_in ? "Wareneingang" : "Warenausgang"}
    </>
  );

  movements = movements.map((movement) => {
    movement.moved = toMovedComponent(movement);
    return movement;
  });
  return (
    <Table
      data={movements}
      headers={deviceMovementsHeaders}
      className="device-movements"
    />
  );
};

export default DeviceMovements;
