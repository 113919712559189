import React, { useEffect, useState } from "react";

import { useContracts } from "../hooks/useApi";
import SearchBar from "../components/SearchBar";
import { sortByKey } from "../lib/sort";
import { Error, Loading } from "../components/Helpers";
import Table from "../components/table/Table";
import { reversValue } from "../lib/text";
import {
  contractsMappings,
  contractsTableHeaders,
} from "../lib/constants/contracts";

import "./Contracts.css";
import { contractLink } from "../lib/links";
import { Link } from "react-router-dom";
import TableToCSV, { csvName } from "../components/TableToCSV";
import Button from "../components/form/Button";
import { SaveOperation } from "../lib/token";
import { H3 } from "../components/screen/Text";

const Contracts = () => {
  const { data, isLoading, error } = useContracts();
  const [contracts, setContracts] = useState([]);

  const onSearch = (_filters = []) => {
    if (!Array.isArray(_filters)) {
      return;
    }
    const filters = _filters.map((filter) => {
      console.log("filter", filter);
      const [key, value] = filter.split(":", 2);
      let originalKey = Object.keys(contractsMappings).find(
        (k) => contractsMappings[k] === key.trim()
      );

      return { key: originalKey, value: reversValue(value.trim()) };
    });


    const filtered = data.contracts.filter((contract) =>
      filters.every((filter) => {
        if (Array.isArray(contract[filter.key])) {
          return contract[filter.key].includes(filter.value);
        }
        // eslint-disable-next-line eqeqeq
        return contract[filter.key] == filter.value;
      })
    );
    // console.log("filtered", filtered.length,data.contracts);
    setContracts(filtered);
  };

  const sortBy = (key, sortDirection = "ASC") => {
    setContracts(sortByKey(contracts, key, sortDirection));
    return false;
  };

  useEffect(() => {
    if (
      data.contracts &&
      Array.isArray(data.contracts) &&
      data.contracts.length > 0
    ) {
      setContracts(data.contracts);
    }
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if(!data.contracts){
    return <div className="pages contracts"><H3>Keine Verträge vorhanden</H3></div>
  }

  // console.log("selectedcontract", selectedcontract);

  return (
    <>
      {contracts && (
        <div className="pages contracts">
          <SaveOperation>
            <div className="button-group-right">
              <Link to={contractLink("new")}>
                <Button
                  text="Neuer Vertrag"
                  type="button"
                  variant="secondary"
                />
              </Link>
              <br />
            </div>
          </SaveOperation>
          <div className="relative">
            <TableToCSV
              headers={[
                {
                  id: "customer",
                  name: "Kunde",
                },
                ...contractsTableHeaders.filter(
                  ({ id }) => !["customer_link", "link"].includes(id)
                ),
              ]}
              data={contracts}
              pullRight={true}
              filename={csvName("vertraege")}
            />
          </div>
          <SearchBar
            suggestionList={contracts}
            onFilter={onSearch}
            placeholder="Suche nach Verträgen (Ort, Kunde, Kundennummer,..)"
            mapKeyValues={contractsMappings}
          />
          <div>
            <Table
              data={contracts}
              onSort={sortBy}
              headers={contractsTableHeaders}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Contracts;
