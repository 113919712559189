import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";

import { useMixedUps } from "../hooks/useApi";
import DevicesWithMoreThanOneOrderPlace from "../components/mixedups/DevicesWithMoreThanOneOrderPlace";
import { Error, Loading } from "../components/Helpers";
import AuthoritiesWithoutLocation from "../components/mixedups/AuthoritiesWithoutLocation";
import DevicesSAPOnly from "../components/mixedups/DevicesSAPOnly";

import "./MixedUp.css";
import { MIXUP_MAPPINGS, conflictStates } from "../lib/constants/mixedUps";
import { sortByKey } from "../lib/sort";
import SearchBar from "../components/SearchBar";
import { reversValue } from "../lib/text";
import DevicesNoOrderLastMonth from "../components/mixedups/DevicesNoOrderLastMonth";
import MasterFile from "../components/mixedups/MasterFile";

const MixedUp = () => {
  const [loadedData, setLoadedData] = useState();
  const { data, isLoading, error } = useMixedUps();
  const [filters, setFilters] = useState(["Aktiv: Ja"]);
  const [changedData, setChangedData] = useState();
  const [suggestionList, setSuggestionList] = useState([]);

  const openStates = conflictStates.slice(0, 2);

  const getType = (array, _type) =>
    array
      .filter(({ type }) => type === _type)
      .filter(({ content }) => content)
      .map((_data) => ({
        ..._data,
        state_id: {
          id: _data.id,
          state: _data.state,
          resolved_at: _data.resolved_at,
          resolved_by: _data.resolved_by,
        },
        __className: !openStates.includes(_data.state) ? "bg-inactive" : "",
        active: openStates.includes(_data.state),
        ...JSON.parse(_data.content),
      }))
      .filter((item) => {
        if (filters && Array.isArray(filters) && filters.length > 0) {
          const filtersClean = filters.map((filter) => {
            const [key, value] = filter.split(":", 2);
            let originalKey = Object.keys(MIXUP_MAPPINGS).find(
              (k) => MIXUP_MAPPINGS[k] === key.trim()
            );

            return { key: originalKey, value: reversValue(value.trim()) };
          });
          return filtersClean.every((filter) => {
            if (Array.isArray(item[filter.key])) {
              return item[filter.key].includes(filter.value);
            }
            return item[filter.key] === filter.value;
          });
        }
        return true;
      });

  const dataToLoadedData = (_data) => {
    const _loadedData = {};
    _loadedData.devices_with_more_than_one_order_place = getType(
      _data,
      "DevicesWithMoreThanOneOrderPlace"
    );
    _loadedData.devices_with_more_than_one_order_place = sortByKey(_loadedData.devices_with_more_than_one_order_place, 'count', 'desc');
    _loadedData.devices_with_more_than_one_order_place = _loadedData.devices_with_more_than_one_order_place.filter((item, index, self) =>
      index === self.findIndex((t) => (
        t.device_id === item.device_id
      ))
    );
    _loadedData.authorities_without_location = getType(
      _data,
      "AuthoritiesWithoutLocation"
    );
    _loadedData.devices_unknown_to_l_and_d = getType(
      _data,
      "DevicesUnknownToLAndD"
    );
    _loadedData.devices_no_order_last_month = getType(
      _data,
      "DevicesWithoutOrdersInLastOrderMonth"
    );
    _loadedData.master_file = getType(_data, "MasterFileMixedUps");
    setLoadedData(_loadedData);
  };

  useEffect(() => {
    if (data?.mixed_up) {
      setChangedData(data.mixed_up);
      dataToLoadedData(data.mixed_up);
      const _suggestionList = data.mixed_up
        .filter(({ content }) => content)
        .map((data) => ({
          ...data,
          active: openStates.includes(data.state),
          ...JSON.parse(data.content),
        }));
      setSuggestionList(_suggestionList);
    }
  }, [data, filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (conflict) => {
    if (conflict.conflict) {
      conflict = conflict.conflict;
    }
    const _data = changedData.map((data) =>
      data.id === conflict.id ? conflict : data
    );
    setChangedData(_data);
    dataToLoadedData(_data);
  };

  const onFilter = (_filters) => {
    if (_filters && Array.isArray(_filters)) {
      setFilters(_filters);
    }
  };

  if (isLoading || !loadedData) return <Loading />;
  if (error) return <Error error={error} />;
  if (!loadedData) return null;
  return (
    <div className="pages mixed-up">
      <SearchBar
        suggestionList={suggestionList}
        onFilter={onFilter}
        placeholder="Status, Zugewiesen an, Kommentar, Aktiv ..."
        mapKeyValues={MIXUP_MAPPINGS}
        preFiltered={["Aktiv: Ja"]}
      />
      <br />
      <div key={CryptoJS.SHA256(JSON.stringify(loadedData)).toString()}>
        <DevicesWithMoreThanOneOrderPlace {...loadedData} onChange={onChange} />
        <br />
        <br />

        <AuthoritiesWithoutLocation {...loadedData} onChange={onChange} />
        <br />
        <br />

        <DevicesSAPOnly {...loadedData} onChange={onChange} />
        <br />
        <br />
        <DevicesNoOrderLastMonth
          {...loadedData}
          last_order_month={data.last_order_month}
          onChange={onChange}
        />
        <br />
        <br />

        <MasterFile {...loadedData} onChange={onChange} />
      </div>
    </div>
  );
};

export default MixedUp;
