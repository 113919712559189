import { germanDay } from "../date";
import { ContractLink, ContractsFilterLink } from "../links";

export const contractsTableHeaders = [
  {
    id: "customer",
    name: "Kunde",
    customFormatter: (_value,contract) => ContractLink(contract),
  },
  {
    id: "description",
    name: "Beschreibung",
  },
  {
    id: "number_of_devices",
    name: "Anzahl Geräte",
    customFormatter: (value) => <div className="text-center">{value}</div>,
  },
  {
    id: "state",
    name: "Status",
  },
  {
    id: "signed_at",
    name: "Vertragsunterschrift",
    customFormatter: germanDay,
  },
];

export const contractsByStateHeaders = [
  {
    id: "state",
    name: "Status",
    customFormatter: (value) => ContractsFilterLink(value, `state:${value}`),
  },
  {
    id: "count",
    name: "Anzahl",
  },
  {
    id: "number_of_devices",
    name: "Anzahl Geräte",
  },
];

export const contractsMappings = {
  customer: "Kunde",
  number_of_devices: "Anzahl Geräte",
  state: "Status",
  description: "Beschreibung",
  contract_type: "Vorgangsart",
  phase: "Phase",
  id: "Opp. ID",
};

export const contractStates = [
  "Offen",
  "Gewonnen",
  "Verloren",
];
