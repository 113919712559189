import React from "react";

import "./Dropdown.css";

const Dropdown = ({ value, options, onChange, disabledOptions }) => {
  const uuid = new Date().getTime();
  const id = `dropdown-${uuid}`;
  return (
          <select
          type="text"
            id={id}
            className="dropdown"
            value={value}
            onChange={onChange}
          >
            {options.map((option) => (
              <option key={option} value={option} disabled={disabledOptions?.includes(option)}>
                {option}
              </option>
            ))}
          </select>
  );
};

export default Dropdown;
