import React, { useState } from "react";
import {
  BdrTab,
  BdrTabContent,
  BdrTabHeader,
  BdrTabHeaderList,
} from "@style-guide/web-components-react";
import UploadComponent from "../components/UploadComponent";
import { H1 } from "../components/screen/Text";
import InfoButton from "../components/screen/Info";
import UploadHistory, { uuidToProcessId } from "../components/UploadHistory";
import { Link } from "react-router-dom";
import { SaveOperation } from "../lib/token";

// Provides an drag and drop area for uploading files
const Upload = () => {
  const [currentTab, setCurrentTab] = useState("tab1");
  const [tabFilter, setTabFilter] = useState([]);
  const [uploadId, setUploadId] = useState(null);
  return (
    <div className="pages upload">
      <BdrTab
        key={`tab${currentTab}`}
        _id="tab_horizontal"
        orientation="horizontal"
        default-active-tab-id={currentTab}
        theme="white"
      >
        <BdrTabHeaderList>
          <BdrTabHeader
            _id="tab1"
            text="Upload"
            onTabChanged={() => setCurrentTab("tab1")}
          />
          <BdrTabHeader
            _id="tab2"
            text="Historie"
            onTabChanged={() => setCurrentTab("tab2")}
          />
        </BdrTabHeaderList>
        <BdrTabContent tab-header-id="tab1">
          <div className="center-text">
            <H1>
              Upload
              <InfoButton>
                Unterstützte Inhalte:
                <br />
                <ul className="list-disc">
                  <li>L&D-API-Dateien</li>
                  <li>SAP-Abrechnungsdateien</li>
                  <li>SAP-Masterdatei</li>
                  <li>CRM-Exportdatei</li>
                </ul>
                <br />
                Alle Datein können als CSV oder XLSX oder mit anderen Dateien
                zusammen in einem ZIP-Archiv hochgeladen werden.
              </InfoButton>
            </H1>

            <SaveOperation altText="Upload ist nur für Nutzer mit Schreibrechten möglich.">
              <UploadComponent
                sucessFunction={async (response) => {
                  setUploadId(uuidToProcessId(response.uuid));
                }}
              />
            </SaveOperation>
          </div>
          {uploadId && (
            <div className="center-text">
              <Link
                onClick={() => {
                  setTabFilter([`Prozess ID: ${uploadId}`]);
                  setCurrentTab("tab2");
                }}
              >
                Upload-Protokoll
              </Link>
            </div>
          )}
        </BdrTabContent>
        <BdrTabContent tab-header-id="tab2">
          <UploadHistory tabFilter={tabFilter} key="upload_history"/>
        </BdrTabContent>
      </BdrTab>
    </div>
  );
};
export default Upload;
