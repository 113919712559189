import React, { useEffect, useState } from "react";
import { useVersionStats } from "../../hooks/useApi";
import { Error, Loading } from "../Helpers";
import Card from "./Card";
import Table from "../table/Table";
import {
  statsByDeviceTypeHeaders,
  statsByVersionHeaders,
} from "../../lib/constants/stats";
import { rateFormater } from "../../lib/numbers";

const StatsByVersion = () => {
  const { data, isLoading, error } = useVersionStats();
  const [statsByVersion, setStatsByVersion] = useState(data?.totals_by_version);
  const [footer, setFooter] = useState(null);


  const getTotalRates = (data) => {
    const totals = data.reduce((sum, d) => sum + d.total, 0);
    const count = data.reduce((sum, d) => sum + d.count, 0);
    if(!totals || !count){
      return 0;
    }
    return count/totals;
  }

  useEffect(() => {
    if (data.totals_by_version) {
      const _statsByVersion = data.totals_by_version.versions.map(({ name }) =>
        statsByDeviceTypeHeaders.reduce(
          (acc, key) => {
            if (!data.totals_by_version[key]) {
              return acc;
            }
            const found = data.totals_by_version[key].find(
              (item) => item.name === name
            );
            if(found && found.hasOwnProperty("rate")){
              acc[key] = found
              return acc;
            }
            if (found) {
              acc[key] = found.count;
            } else {
              acc[key] = 0;
            }
            return acc;
          },
          { name }
        )
      );
      console.log(_statsByVersion);
      setStatsByVersion(_statsByVersion);

      // Footer [{id:name, text: "Summe"},...]
      const _footer = statsByVersionHeaders.map(({id}) =>{
        if(id === "name"){
          return { id, text: "Summe" };
        }
        if(id === "exchange_rate_active"){
          return { id, text: rateFormater(getTotalRates(data.totals_by_version.exchange_rate_active)), className: "align-right" };
        }
        if(id === "exchange_rate_all"){
          return { id, text: rateFormater(getTotalRates(data.totals_by_version.exchange_rate_all)), className: "align-right" };
        }
        return { id, text: _statsByVersion.reduce((sum, d) => sum + d[id], 0) };
      });
      
      setFooter(_footer);
    }
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  return (
    <>
      <Card title="">
        {statsByVersion && (
          <Table 
          data={statsByVersion} 
          headers={statsByVersionHeaders} 
          footers={footer||[]}
          />
        )}
      </Card>
    </>
  );
};

export default StatsByVersion;
