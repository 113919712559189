import React from "react";
import { Pie, PieChart } from "recharts";
import { Link } from "react-router-dom";
import { secondary } from "../../lib/colors";

const renderCustomizedLabel = (props) => {
  const { name, percent, x, y, textAnchor, searchKey } = props;
  return (
    <text x={x} y={y} textAnchor={textAnchor} style={{ fontSize: "0.5em" }}>
      <Link to={`/devices#${searchKey}:${name}`}>
        {`${name} (${(percent * 100).toFixed(0)}%)`}
      </Link>
    </text>
  );
};

const PieChartOneValue = ({ objects, height, width, showLabels = true }) => {
  if (!Array.isArray(objects)) {
    return null;
  }
  width = width || 450;
  height = height || 200;

  return (
    <div style={{ marginTop: "50px" }}>
      <PieChart width={width} height={height} data={objects}>
        <Pie
          data={objects}
          dataKey="count"
          fill={secondary}
          nameKey="name"
          label={
            showLabels ? (props) => renderCustomizedLabel({ ...props }) : null
          }
        />
      </PieChart>
    </div>
  );
};

export default PieChartOneValue;
