import React, { useEffect, useState } from "react";
import { EditableField, MultiCheckboxes } from "../Field";
import { useInfo } from "../../../hooks/useApi";

const RuleForm = ({ rule, setRule, editMode = true }) => {
  const { data } = useInfo();
  const [versions, setVersions] = useState([]);

  useEffect(() => {
    if (data?.versions) {
      setVersions(data.versions);
    }
  }, [data]);

  const editableField = (displayName, field, value, type = "input") => (
    <EditableField
      displayName={displayName}
      field={field}
      value={value}
      type={type}
      editMode={editMode}
      onChange={(e) => {
        console.log("onChange", field, e.target.value);
        setRule({ ...rule, [field]: e.target.value });
      }}
    />
  );

  return (
    <>
      {editableField("Name", "name", rule.name)}
      {editableField("Gültig ab", "valid_from", rule.valid_from, "date")}
      {
        <MultiCheckboxes
          displayName="Versionen"
          value={rule.affected_versions}
          possibleValues={versions}
          onChange={(e) => {
            setRule({ ...rule, affected_versions: e.target.value });
          }}
          editMode={editMode}
        />
      }
      {editableField("Kommentar", "description", rule.description, "textarea")}
      {editableField("Jira-Ticket", "jira_key", rule.jira_key)}
    </>
  );
};

export default RuleForm;
