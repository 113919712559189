import React from "react";
import { Link } from "react-router-dom";
import InLine from "./InLine";
import Card from "./Card";

import "./TopRow.css";
import { H1, H4, H5 } from "../screen/Text";

const TOTALS = [
  { title: "In Betrieb", field: "devices_delivered" },
  { title: "In Reparatur", field: "devices_in_repair", showMore: true },
  { title: "Verschrottet", field: "devices_sorted_out" },
];

const OpenDetailLink = ({ setOpenDetail, field, openDetail }) => (
  <Link
    className="more-link"
    onClick={(e) => {
      e.preventDefault();
      setOpenDetail(field);
    }}
  >
    {openDetail === field ? "einklappen" : "mehr..."}{" "}
  </Link>
);

const TopRow = ({ totals, setOpenDetail, openDetail }) => (
  <InLine>
    <Card title="Bereit zur Auslieferung">
      <div className="grid-cols-3">
        <div>
          <H1>{totals.devices_ready_for_delivery}</H1>
        </div>
        <div className="small-grid-item">
          <H1>/</H1>
        </div>
        <div>
          <H1>{totals.devices_ready_for_delivery_old}</H1>
        </div>
        <div>
          <H4>neu</H4>
        </div>
        <div className="small-grid-item"></div>
        <div>
          <H4>alt</H4>
        </div>
      </div>
      <OpenDetailLink
        field="stats_by_version"
        setOpenDetail={setOpenDetail}
        openDetail={openDetail}
      />
    </Card>
    {TOTALS.map((item, index) => (
      <Card title={item.title} number={totals[item.field]} key={index}>
        {item.showMore && (
          <OpenDetailLink
            field={item.field}
            setOpenDetail={setOpenDetail}
            openDetail={openDetail}
          />
        )}
      </Card>
    ))}
    <Card title="Verträge offen">
      <div className="grid-cols-3">
        <div>
          <H1>{totals.contracts_open}</H1>
        </div>
        <div className="small-grid-item">
          <H1>/</H1>
        </div>
        <div>
          <H1>{totals.contracts_open_devices}</H1>
        </div>
        <div>
          <H5>Verträge</H5>
        </div>
        <div className="small-grid-item"></div>
        <div>
          <H5>Geräte</H5>
        </div>
      </div>
      <OpenDetailLink
        field="contracts_by_state"
        setOpenDetail={setOpenDetail}
        openDetail={openDetail}
      />
    </Card>
  </InLine>
);

export default TopRow;
