

const TOKEN_STORAGE = "access.token"


export const setToken = (token) => localStorage.setItem(TOKEN_STORAGE, token)
export const getToken = () => localStorage.getItem(TOKEN_STORAGE)
export const removeToken = () => localStorage.removeItem(TOKEN_STORAGE)
export const getUserIdNameFromJwtToken = () => extractFromJwtToken("user_id")
export const getRoleFromJwtToken = () => extractFromJwtToken("role")

const extractFromJwtToken = (key) => {
    const token = getToken()
    if (!token) return null
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))[key]
}


export const RenderForRole = ({ role, children }) => {
    const userRole = getRoleFromJwtToken()
    if (userRole === role) {
        return children
    }
    return null
}

export const SaveOperation = ({ children, altText }) => {
    const userRole = getRoleFromJwtToken()
    if (userRole !== "ReadOnly") {
        return children
    }
    return altText
}