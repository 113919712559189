import React, { useEffect, useMemo, useState } from "react";
import CryptoJS from "crypto-js";

import { directOrderStats } from "../hooks/useApi";
import { Error, Loading } from "./Helpers";
import Table from "./table/Table";
import { sortByKey } from "../lib/sort";
import { euroFormater, markZeroRed } from "../lib/numbers";
import Dropdown from "./form/Dropdown";
import SearchBar from "./SearchBar";
import { noZero, reversValue } from "../lib/text";
import { deviceLinkFormatter } from "../lib/links";
import TableToCSV from "./TableToCSV";
import ExchangededDevices, { findMatches } from "./ExchangededDevices";

const defaultHeaders = [
  { id: "location", name: "Standort" },
  { id: "device_id", name: "SST", customFormatter: deviceLinkFormatter },
  { id: "minimum_sales", name: "Mindestumsatz", customFormatter: euroFormater },
  { id: "sum", name: "Summe" },
  {
    id: "process_engeneer",
    name: "Verfahrensentwickler",
    customFormatter: noZero,
  },
];

const FinanceOrdersperDeviceDetail = ({ year, setFilters, filters }) => {
  const filterableFields = ["minimum_sales", "process_engeneer", "sum"];

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loadedData, setLoadedData] = useState([]);
  const [hiddenFields, setHiddenFields] = useState(filterableFields);
  const [matches, setMatches] = useState([]);
  const [location, setLocation] = useState();
  const [showAll, setShowAll] = useState(false);

  const months = useMemo(() => {
    const _months = [];
    for (let i = 1; i <= 12; i++) {
      _months.push(`${year}-${i.toString().padStart(2, "0")}`);
    }
    return _months;
  }, [year]);

  const sortBy = (key, sortDirection = "ASC") => {
    setLoadedData(sortByKey(loadedData, key, sortDirection));
  };

  const FILTERS = {
    location: "Standort",
    device_id: "SST",
    minimum_sales: "Mindestumsatz",
    process_engeneer: "Verfahrensentwickler",
    sum: "Summe",
  };

  const onSearch = (_filters = []) => {
    if (!Array.isArray(_filters)) {
      return;
    }
    const filters = _filters.map((filter) => {
      const [key, value] = filter.split(":", 2);
      let originalKey = Object.keys(FILTERS).find(
        (k) => FILTERS[k] === key.trim()
      );
      return { key: originalKey, value: reversValue(value.trim()) };
    });
    const filtered = data.filter((item) =>
      filters.every((filter) => {
        if (Array.isArray(item[filter.key])) {
          return item[filter.key].includes(filter.value);
        }
        return String(item[filter.key]) === String(filter.value);
      })
    );

    handleData(filtered);
  };

  useEffect(() => {
    if (filters && filters.find((f) => f.includes("Standort"))) {
      setMatches(findMatches(loadedData, months));
      setLocation(
        filters
          .find((f) => f.includes("Standort"))
          .split(":")[1]
          .trim()
      );
    } else {
      setMatches([]);
    }
  }, [loadedData, filters, months]);

  const handleData = (__data) => {
    if (!__data) return;

    const defaultValues = months.reduce((acc, m) => {
      acc[m] = 0;
      return acc;
    }, {});

    const _data = {};
    for (let i = 0; i < __data.length; i++) {
      const key = __data[i].device_id + __data[i].location;
      _data[key] = _data[key] || {
        ...defaultValues,
        location: __data[i].location,
        device_id: __data[i].device_id,
        minimum_sales: __data[i].minimum_sales,
        process_engeneer: __data[i].process_engeneer,
      };
      _data[key][__data[i].month] = __data[i].amount;
    }
    for (let i = 0; i < Object.keys(_data).length; i++) {
      const key = Object.keys(_data)[i];
      _data[key].sum = Object.values(_data[key]).reduce((acc, v) => {
        if (typeof v === "number") {
          return acc + v;
        }
        return acc;
      }, 0);
    }
    setLoadedData(sortByKey(Object.values(_data), "location"));
  };
  useEffect(() => {
    directOrderStats(year)
      .catch((e) => {
        setError(e);
        setIsLoading(false);
      })
      .then((data) => {
        setData(data);
        setIsLoading(false);
        handleData(data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  const csvHeaders = [
    ...defaultHeaders.filter((h) => h.id !== "sum"),
    ...months.sort().map((m) => ({
      id: m,
      name: m,
    })),
    { id: "sum", name: "Summe" },
  ].filter((h) => !hiddenFields.includes(h.id));
  const headers = [
    ...defaultHeaders.filter((h) => h.id !== "sum"),
    ...months.sort().map((m) => ({
      id: m,
      name: m.replace(`${year}-`, ""),
      customFormatter: markZeroRed,
    })),
    { id: "sum", name: "Summe" },
  ].filter((h) => !hiddenFields.includes(h.id));

  const download = (
    <TableToCSV
      data={loadedData}
      headers={csvHeaders}
      filename={`reporting_bestellungen_pro_sst_${year}.csv`}
      pullRight={true}
    />
  );

  const options = filterableFields.map((field) => (
    <label key={`hide-${field}`}>
      <input
        type="checkbox"
        checked={!hiddenFields.includes(field)}
        onChange={(e) => {
          if (!e.target.checked) {
            setHiddenFields([...hiddenFields, field]);
          } else {
            setHiddenFields(hiddenFields.filter((f) => f !== field));
          }
        }}
      />{" "}
      {defaultHeaders.find((h) => h.id === field).name}
    </label>
  ));
  return (
    <>
      <span className="hide-fields">
        {options} {download}
      </span>
      <div className="small-table">
        <SearchBar
          key={CryptoJS.SHA256(JSON.stringify(FILTERS)).toString()}
          suggestionList={loadedData}
          mapKeyValues={FILTERS}
          onFilter={(_data) => {
            onSearch(_data);
            if (_data) {
              setFilters(_data);
            }
          }}
          preFiltered={filters}
          placeholder="Suche nach SST (Standort, Seriennummer, Verfahrensentwickler, Mindestumsatz)"
        />
        <Table
          key={CryptoJS.SHA256(JSON.stringify(headers)).toString()}
          onSort={sortBy}
          data={showAll ? loadedData : loadedData.slice(0, 50)}
          headers={headers}
          footers
        />
        {!showAll && loadedData.length > 50 && (
          <>
            {" "}
            Gefilterete Anzeige 50 von {loadedData.length} Zeilen{" "}
            <a
              className="bdr-button bdr-button--secondary"
              onClick={(e) => {
                e.preventDefault();
                setShowAll(true);
              }}
              href="/finance"
            >
              Alle anzeigen
            </a>
          </>
        )}
        {matches && matches.length > 0 && (
          <ExchangededDevices
            months={months}
            matches={matches}
            location={location}
            year={year}
          />
        )}
      </div>
    </>
  );
};

const FinanceOrdersperDevice = ({ tabFilter, tabYear }) => {
  const [year, setYear] = useState(tabYear);
  const [filters, setFilters] = useState(tabFilter);
  const years = [];
  for (let i = 2015; i <= new Date().getFullYear(); i++) {
    years.push(i);
  }
  return (
    <>
      <Dropdown
        value={year}
        options={years}
        onChange={(e) => setYear(e.target.value)}
      />
      <FinanceOrdersperDeviceDetail
        year={year}
        key={year}
        setFilters={setFilters}
        filters={filters}
      />
    </>
  );
};
export default FinanceOrdersperDevice;
