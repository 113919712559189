import React, { useEffect, useState } from "react";
import { changeConflict, useConflict } from "../hooks/useApi";
import { Error, Loading } from "../components/Helpers";
import { useNavigate, useParams } from "react-router-dom";
import { BdrTypo } from "@style-guide/web-components-react";
import { EditButtonGroup } from "../components/form/Editbuttons";
import ConflictForm from "../components/form/forms/ConflictForm";
import {
  devicesWithoutOrdersInLastOrderMonthTableHeaders,
  masterFileHeaders,
  mixedAuthoritiesTableHeaders,
  mixedPlacesTableHeaders,
  mixedSAPOnlyTableHeaders,
} from "../lib/constants/mixedUps";
import { displayArray } from "../lib/text";
import BackButton from "../components/nav/Back";
import { H1 } from "../components/screen/Text";

const Conflict = () => {
  const { id } = useParams();
  const { data, isLoading, error } = useConflict(id);
  const [conflict, setConflict] = useState();
  const [editMode, setEditMode] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!data || !data.conflict) return;

    setConflict(data.conflict);
  }, [data]);

  if (isLoading || !conflict) return <Loading />;
  if (error) return <Error error={error} />;

  const TYPES = {
    DevicesWithMoreThanOneOrderPlace: "Gerät mit mehr als einem Bestellplatz",
    AuthoritiesWithoutLocation: "Behörde ohne Standort",
    DevicesUnknownToLAndD: "Gerät unbekannt bei L&D",
  };

  const HEADERS = {
    DevicesWithMoreThanOneOrderPlace: mixedPlacesTableHeaders,
    AuthoritiesWithoutLocation: mixedAuthoritiesTableHeaders,
    DevicesUnknownToLAndD: mixedSAPOnlyTableHeaders,
    MasterFileMixedUps: masterFileHeaders,
    DevicesWithoutOrdersInLastOrderMonth: devicesWithoutOrdersInLastOrderMonthTableHeaders,
  };

  const cancel = () => {
    navigate(-1);
  };
  const submit = async () => {
    await changeConflict(conflict);
    navigate(-1);
  };
  const content = JSON.parse(conflict.content);


  return (
    <div className="pages device-card editable">
      <BackButton />
      <H1>{TYPES[conflict.type]}</H1>

      <EditButtonGroup
        editMode={editMode}
        setEditMode={setEditMode}
        cancel={cancel}
        save={submit}
      />

      {HEADERS[conflict.type].map((header) => (
        <div className="table-row">
          <div className="table-cell pr-4">
            <BdrTypo type="body-medium">{header.name}</BdrTypo>
          </div>
          <div className="table-cell"></div>
          {displayArray(content[header.id])}
        </div>
      ))}
      <ConflictForm
        conflict={conflict}
        editMode={editMode}
        setConflict={setConflict}
      />
    </div>
  );
};

export default Conflict;
