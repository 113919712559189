import {
  BdrDatePicker,
  BdrInputPassword,
  BdrInputText,
  BdrTextarea,
} from "@style-guide/web-components-react";
import React from "react";
import Dropdown from "./Dropdown";

const Input = ({ value, onChange, type = "text" }) => {
  if (type === "date") {
    const parse = (value) => {
      // console.log("parse", value);
      if (!value) return null;
      if (value.includes(".")) {
        const [day, month, year] = value.split(".");
        return new Date(year, month - 1, day);
      }
      return new Date(value);
    };
    const format = (value) => {
      // console.log("format", value);
      if (!value) return null;
      if (typeof value === "string" && value.includes(".")) return value;
      const date = new Date(value);
      let month = (date.getMonth() + 1).toString(10);
      if (month < 10) {
        month = `0${month}`;
      }
      let day = date.getDate().toString(10);
      if (day < 10) {
        day = `0${day}`;
      }
      const year = date.getFullYear().toString(10);

      return `${day}.${month}.${year}`;
    };
    return (
      <BdrDatePicker
        dateAdapter={{
          parse,
          format,
        }}
        value={format(value)}
        onValueChange={({ detail: { newValue } }) => {
          onChange({ target: { value: parse(newValue) } });
        }}
      />
    );
  }
  if (type === "dropdown") {
    return (
      <Dropdown
        value={value}
        options={["Admin", "ReadOnly"]}
        onChange={onChange}
      />
    );
  }
  if (type === "textarea") {
    return <BdrTextarea rows="10" value={value} inputChange={onChange} />;
  }
  if (type === "number")
    return (
      <BdrInputText
        type="number"
        value={value}
        inputChange={(e) => {
          onChange({ target: { value: parseInt(e.target.value, 10) } });
        }}
      />
    );
  if (type === "password") {
    return (
      <BdrInputPassword type="password" value={value} inputChange={onChange} />
    );
  }
  return <BdrInputText type="text" value={value} inputChange={onChange} />;
};
export default Input;
