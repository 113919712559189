import { BdrTypo } from "@style-guide/web-components-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BdrPictogram } from "@style-guide/web-components-react";

import BACK from "@style-guide/assets/icons/chevron/left.svg"

const BackButton = ({link}) => {
  const navigate = useNavigate();
  const [backPic, setBackPic] = useState();
  fetch(BACK).then((r) => r.text()).then(setBackPic);
  const back = () => {
    navigate(link || -1);
  }
  return (
    <BdrTypo type="body-medium" className="back-button" onClick={back}>
        <BdrPictogram pictogram={backPic} alternativeText="Zurück" />
    </BdrTypo>
  );
};

export default BackButton;