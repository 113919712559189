import { BdrTypo } from "@style-guide/web-components-react";
import React from "react";
import DeviceDetail from "./DeviceDetail";
import Table from "../table/Table";
import { repairsTableHeadersNoSerialNumberNoDays } from "../../lib/constants/repairs";

import "./DeviceCard.css";
import { TableChartMonth } from "../stats/TableChart";
import DeviceMovements from "./DeviceMovements";
import DeviceOrdersWrapper from "./DeviceOrdersWrapper";
import { BodyText, H1, H2 } from "../screen/Text";
const DeviceCard = ({ device }) => {
  return (
    <>
      <div className="pages device-card">
        <H1>SST: {device.serial_number}</H1>
        <br />
        <br />
        <H2 id="repair-headline">Reparaturen</H2>
        {device.repairs.length === 0 && (
          <BodyText>Keine Reparaturen zu diesem SST erfasst</BodyText>
        )}
        {device.repairs.length > 0 && (
          <Table
            _id="repair-table"
            data={device.repairs}
            headers={repairsTableHeadersNoSerialNumberNoDays}
          />
        )}
        <br />
        <br />
        <TableChartMonth
          data={device.orders}
          title="Transaktionen"
          noDataText={`Keine Transaktionen zu diesem SST erfasst`}
          groupKey="product_type"
          sumField="amount"
        />
        <br />
        <br />
        <TableChartMonth
          data={device.orders}
          title="Umsatz"
          noDataText={`Kein Umsatz zu diesem SST erfasst`}
          groupKey="product_type"
          sumField="transaction_value"
        />
        <br />
        <br />

        <BdrTypo text="Details" type="headline-2" />
        <DeviceDetail device={device} />

        <br />
        <br />

        <BdrTypo text="Warenein-/-ausgänge" type="headline-2" />
        <DeviceMovements movements={device.movements} />
        <br />
        <br />

        <DeviceOrdersWrapper deviceId={device.serial_number} />
      </div>
    </>
  );
};

export default DeviceCard;
