import React from "react";
import FILE from "@style-guide/assets/pictograms/document_input.svg";
import { BdrPictogram } from "@style-guide/web-components-react";

import "./ContractAttachment.css";
import { deleteContractAttachment } from "../hooks/useApi";

const ContractAttachment = ({ contract, onDelete }) => {
  const [filePic, setFilePic] = React.useState();

  fetch(FILE)
    .then((r) => r.text())
    .then((text) => {
      setFilePic(text);
    });

  if (!contract.attached_files) return null;
  return (
    <>
      <div className="attachments">
        {contract.attached_files.map((attachment, i) => {
          return (
            <div className="attachment" key={i}>
              <a href={attachment.file_path} target="_blank" rel="noreferrer">
                <BdrPictogram pictogram={filePic} />
                {attachment.original_file_name}
              </a>
              <a className="delete"
                href="/contacts"
                onClick={async (e) => {
                  e.preventDefault();
                  const isOK = window.confirm(
                    "Anlage wirklich löschen?"
                  );
                  if (!isOK) return;
                  await deleteContractAttachment(attachment);
                  if (onDelete) {
                    onDelete();
                  }
                }}
              >
                X
              </a>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ContractAttachment;
