import React from "react";
import Table from "../table/Table";
import TableToCSV, { csvName } from "../TableToCSV";
import {
  commonMixedUpsTableHeaders,
  mixedSAPOnlyTableHeaders,
} from "../../lib/constants/mixedUps";
import InfoButton from "../screen/Info";
import { H2 } from "../screen/Text";

const DevicesNoOrderLastMonth = ({
  devices_no_order_last_month,
  last_order_month,
}) => {
  const headers = [...mixedSAPOnlyTableHeaders, ...commonMixedUpsTableHeaders];
  devices_no_order_last_month = devices_no_order_last_month || [];
  return (
    <div className="content">
      <H2>
        SSTs ohne Transaktion in {last_order_month || ""} (
        {devices_no_order_last_month.length})
        <InfoButton>Hier werden nur SSTs ohne Mindestumsatz angezeigt</InfoButton>
      </H2>

      <Table headers={headers} data={devices_no_order_last_month} />
      <TableToCSV
        headers={mixedSAPOnlyTableHeaders}
        data={devices_no_order_last_month}
        filename={csvName("devices_no_order_last_month")}
        pullRight={true}
      />
    </div>
  );
};

export default DevicesNoOrderLastMonth;
