import React from "react";
import { BdrCard } from "@style-guide/web-components-react";

import "./Card.css";
import { H1, H4 } from "../screen/Text";

const Card = ({ title, children, number, style, header }) => (
  <BdrCard
    decorationVisible={true}
    alignment="vertical"
    style={style}
    className="custom-card"
  >
    <div slot="card-content">
    {header && (
      <div className="card-header">
        {header}
      </div>
    )}
      <div className="card-content">
        <H4>{title}</H4>
        {typeof number !== "undefined" && (
          <H1 className="card-number">{number}</H1>
        )}
        {children}
      </div>
    </div>
  </BdrCard>
);

export default Card;
