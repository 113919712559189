import { BdrTypo } from "@style-guide/web-components-react";
import React from "react";
import Table from "../table/Table";
import TableToCSV, { csvName } from "../TableToCSV";
import {
  commonMixedUpsTableHeaders,
  mixedAuthoritiesTableHeaders,
} from "../../lib/constants/mixedUps";

const AuthoritiesWithoutLocation = ({
  authorities_without_location,
  onChange,
}) => {
  const sorted_authorities_without_location = authorities_without_location.sort(
    (a, b) => b.last_order_date?.localeCompare(a.last_order_date)
  );
  const headers = [
    ...mixedAuthoritiesTableHeaders,
    ...commonMixedUpsTableHeaders,
  ];
  return (
    <div className="content">
      <BdrTypo
        text={`BHKZs ohne Standort (${sorted_authorities_without_location.length})`}
        type="headline-2"
      />
      <BdrTypo text="Kein Eintrag in der Masterdatei" type="headline-5" />
      <br />
      <Table data={sorted_authorities_without_location} headers={headers} />
      <TableToCSV
        headers={mixedAuthoritiesTableHeaders}
        data={sorted_authorities_without_location}
        filename={csvName("bhkz_ohne_standort")}
        pullRight={true}
      />
    </div>
  );
};

export default AuthoritiesWithoutLocation;
