import { germanMonth } from "../date";
import { toGermanInteger } from "../numbers";

export const ordersTableHeaders = [
  { id: "serial_number", name: "SST" },
  { id: "order_month", name: "Monat", customFormatter: germanMonth },
  { id: "amount", name: "Anzahl" },
  { id: "authority_code", name: "BHKZ" },
];

export const ordersTableHeadersNoSerialNumber = ordersTableHeaders.filter(
  (header) => header.id !== "serial_number"
);

export const ordersTableHeadersNoSerialNumberNoAuthorty =
  ordersTableHeaders.filter(
    (header) => header.id !== "serial_number" && header.id !== "authority_code"
  );

export const ordersByDeviceTableHeaders = [
  { id: "order_month", name: "Monat", customFormatter: germanMonth },
  { id: "amount", name: "Anzahl" },
  { id: "product_type", name: "Produktart" },
  { id: "authority_id", name: "BHKZ" },
  { id: "transaction_price", name: "Preis pro Transaktion", unit: "€" },
  { id: "transaction_value", name: "Umsatz", unit: "€" },
  { id: "location", name: "Standort" },
];

export const financeTableHeaders = [
  {
    id: "month",
    name: "Monat",
    customFormatter: germanMonth,
  },
  {
    id: "orders",
    name: "Transaktionen",
  },
  {
    id: "sales",
    name: "Umsatz SSTs",
    customFormatter: (value)=>toGermanInteger(value,"€"),
    className: "align-right",
  },
  {
    id: "otherSales",
    name: "Sonstiger Umsatz",
    customFormatter: (value)=>toGermanInteger(value,"€"),
    className: "align-right",
  },
  {
    id: "sumSales",
    name: "Gesamtumsatz",
    customFormatter: (value)=>toGermanInteger(value,"€"),
    className: "align-right",
  },
];
export const financeTableHeadersShort = financeTableHeaders.filter(({id})=>!["otherSales","sumSales"].includes(id));

export const financeTableHeadersYear = [
  {
    id: "year",
    name: "Jahr",
  },
  {
    id: "orders",
    name: "Transaktionen",
  },
  {
    id: "sales",
    name: "Umsatz SSTs",
    unit: " €",
    customFormatter: (value)=>toGermanInteger(value,"€"),
    className: "align-right",
  },
  {
    id: "otherSales",
    name: "Sonstiger Umsatz",
    unit: " €",
    customFormatter: (value)=>toGermanInteger(value,"€"),
    className: "align-right",
  },
  {
    id: "sumSales",
    name: "Gesamtumsatz",
    unit: " €",
    customFormatter: (value)=>toGermanInteger(value,"€"),
    className: "align-right",
  },
];

export const financeTableHeadersYearShort = financeTableHeadersYear.filter(({id})=>!["otherSales","sumSales"].includes(id));

export const financeMappings = {
  location: "Standort",
  authority_id: "BHKZ",
  product_type: "Produktart",
}
