import React from "react";
import { Route, Routes as RouterRoutes } from "react-router-dom";

import Devices from "./pages/Devices";
import Device from "./pages/Device";
import Repairs from "./pages/Repairs";
import Login from "./pages/Login";
import Stats from "./pages/Stats";
import Upload from "./pages/Upload";
import Finance from "./pages/Finance";
import MixedUp from "./pages/MixedUp";
import Conflict from "./pages/Conflict";
import Contracts from "./pages/Contracts";
import Contract from "./pages/Contract";
import withAuthentication from "./components/withAuthentication";
import NewContract from "./pages/NewContract";
import Settings from "./pages/Settings";
import NewRule from "./pages/NewRule";
import EditRule from "./pages/EditRule";
import EditUser from "./pages/EditUser";
import NewUser from "./pages/NewUser";
import NewSale from "./pages/NewSale";
import EditSale from "./pages/EditSale";

const DevicesWithAuth = withAuthentication(Devices);
const DeviceWithAuth = withAuthentication(Device);
const RepairsWithAuth = withAuthentication(Repairs);
const StatsWithAuth = withAuthentication(Stats);
const UploadWithAuth = withAuthentication(Upload);
const FinanceWithAuth = withAuthentication(Finance);
const MixedUpWithAuth = withAuthentication(MixedUp);
const ConflictWithAuth = withAuthentication(Conflict);
const ContractsWithAuth = withAuthentication(Contracts);
const ContractWithAuth = withAuthentication(Contract);
const NewContractWithAuth = withAuthentication(NewContract);
const SettingsWithAuth = withAuthentication(Settings);
const NewRuleWithAuth = withAuthentication(NewRule);
const EditRuleWithAuth = withAuthentication(EditRule);
const EditUserWithAuth = withAuthentication(EditUser);
const NewUserWithAuth = withAuthentication(NewUser);
const NewSaleWithAuth = withAuthentication(NewSale);
const EditSaleWithAuth = withAuthentication(EditSale);

export const WIDE_PAGES = ["/repairs","/contracts","/finance","/mixedup","/settings","/upload"];

const Routes = () => (
  <RouterRoutes>
    <Route exact path="/" element={<StatsWithAuth />} />
    <Route exact path="/ui" element={<StatsWithAuth />} />
    <Route exact path="/devices" element={<DevicesWithAuth />} />
    <Route exact path="/devices/:id" element={<DeviceWithAuth />} />
    <Route exact path="/repairs" element={<RepairsWithAuth />} />
    <Route exact path="/upload" element={<UploadWithAuth />} />
    <Route exact path="/finance" element={<FinanceWithAuth />} />
    <Route exact path="/mixedup" element={<MixedUpWithAuth />} />
    <Route exact path="/mixedup/:id" element={<ConflictWithAuth />} />
    <Route exact path="/contracts" element={<ContractsWithAuth />} />
    <Route exact path="/contracts/:id" element={<ContractWithAuth />} />
    <Route exact path="/contracts/new" element={<NewContractWithAuth />} />
    <Route exact path="/settings" element={<SettingsWithAuth />} />
    <Route exact path="/settings/rules/new" element={<NewRuleWithAuth />} />
    <Route exact path="/settings/rules/:id/" element={<EditRuleWithAuth />} />
    <Route exact path="/settings/users/:id/" element={<EditUserWithAuth />} />
    <Route exact path="/settings/users/new/" element={<NewUserWithAuth />} />
    <Route exact path="/sales/new" element={<NewSaleWithAuth />} />
    <Route exact path="/sales/:id" element={<EditSaleWithAuth />} />
    <Route exact path="/login" element={<Login />} />
  </RouterRoutes>
);

export default Routes
