import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserForm from "../components/form/forms/UserForm";
import {
  deleteUser,
  resetUserPassword,
  updateUser,
  useUsers,
} from "../hooks/useApi";
import { usersLink } from "../lib/links";
import {
  Error,
  ErrorMessageBox,
  Loading,
  WarningMessageBox,
} from "../components/Helpers";
import { EditButtonGroup } from "../components/form/Editbuttons";
import { getUserIdNameFromJwtToken } from "../lib/token";
import { handleErrorResponse } from "../lib/errors";
import { EditableField } from "../components/form/Field";
import Button from "../components/form/Button";

const EditUser = () => {
  const { id } = useParams();
  const { data, error, isLoading } = useUsers();
  const [user, setUser] = useState({});
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [didCreateNewPassword, setDidCreateNewPassword] = useState(false);

  useEffect(() => {
    if (data && data.users) {
      setUser(data.users.find((u) => u.id === parseInt(id, 10)) || {});
    }
  }, [data, id]);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  const reset = () => {
    navigate(usersLink);
  };

  const resetPassword = async () => {
    try {
      const result = await resetUserPassword(user);
      setNewPassword(
        <>
          Das neue Passwort für "{user.username}" lautet: {result.newPassword}
          <br />
          <br />
          Versenden Sie das Passwort an den Benutzer in einer sicheren Form.
          <br />
          Das Passwort ist nur einmalig sichtbar. Kopieren Sie es jetzt.
        </>
      );
    } catch (error) {
      console.log("error updateUser", error);
      const errorMessage = handleErrorResponse(error.message);
      setErrorText(errorMessage);
    }
  };

  const submit = async () => {
    try {
      await updateUser(user);
      reset();
    } catch (error) {
      console.log("error updateUser", error);
      const errorMessage = handleErrorResponse(error.message);
      setErrorText(errorMessage);
    }
  };
  const remove = async () => {
    if (user.id === getUserIdNameFromJwtToken()) {
      setErrorText("Sie können sich nicht selbst löschen");
      return;
    }
    const isOK = window.confirm("User löschen?");
    if (!isOK) return;
    try {
      await deleteUser(user);
      reset();
    } catch (error) {
      const errorMessage = handleErrorResponse(error.message);
      setErrorText(errorMessage);
    }
  };

  if (
    user?.id &&
    user.created_at === user.updated_at &&
    !didCreateNewPassword
  ) {
    resetPassword();
    setDidCreateNewPassword(true);
  }

  return (
    <div className="pages device-card editable">
      {<WarningMessageBox success={newPassword} />}
      {<ErrorMessageBox error={errorText} />}
      <EditButtonGroup
        editMode={editMode}
        setEditMode={setEditMode}
        cancel={reset}
        save={submit}
        del={remove}
      />
      <UserForm user={user} setUser={setUser} editMode={editMode} />
      <EditableField
        displayName="Passwort"
        editMode={true}
        input={
          <Button variant="secondary" onClick={resetPassword}>
            zurücksetzen
          </Button>
        }
        value={""}
      />
    </div>
  );
};

export default EditUser;
