import React, { useCallback, useState } from "react";
import {
  BdrButton,
  BdrProgressIndicator,
  BdrPictogram,
} from "@style-guide/web-components-react";
import CHECK from "@style-guide/assets/pictograms/check.svg";

import Dropzone from "react-dropzone";
import "../components/DragDrop.css";
import { UploadDirect } from "../hooks/useApi";
import { Error } from "./Helpers";
import { H5, VerySmallText } from "./screen/Text";

const TYPES_TO_MIME = {
  csv: ["text/csv", "text/plain", "application/vnd.ms-excel", ".csv"],
  zip: [
    "application/zip",
    "application/x-zip-compressed",
    "application/x-zip",
    ".zip",
  ],
  pdf: ["application/pdf", ".pdf"],
  jpg: ["image/jpeg", ".jpg"],
  png: ["image/png", ".png"],
  xls: ["application/vnd.ms-excel", ".xls", ".xlsx"],
};

// Provides an drag and drop area for uploading files
const UploadComponent = (props) => {
  const _props = { ...props };
  if (_props.children) {
    _props.hint = _props.children;
  }
  if (!_props.hint) {
    _props.hint = "Ziehen Sie CSV-,PDF- oder ZIP-Dateien hierher";
  }
  if (!_props.size) {
    _props.size = "large";
  }
  if (!_props.types) {
    _props.types = ["csv", "zip", "xls"];
  }

  const [dragActive, setDragActive] = useState(false);
  const [svgPictogram, setSvgPictogram] = useState();
  const [hint, setHint] = useState(_props.hint);
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const onDragEnter = useCallback(() => setDragActive(true), []);
  const onDragLeave = useCallback(() => setDragActive(false), []);

  const onDrop = useCallback((acceptedFiles) => {
    setDragActive(false);
    if (acceptedFiles.length === 0) {
      setHint("Erlaubte Dateitypen: "._props.types.join(", "));
      return;
    }
    setHint(acceptedFiles.map((file, i) => <div key={i}>{file.name}</div>));
    setFiles(acceptedFiles);
    console.log(acceptedFiles);
  }, []);

  fetch(CHECK)
    .then((r) => r.text())
    .then((text) => {
      setSvgPictogram(text);
    });

  const sendFiles = () => {
    console.log("Upload", files);
    setIsUploading(true);
    setHint(
      <>
        <BdrProgressIndicator />
        Dateien werden hochgeladen...
      </>
    );
    UploadDirect(files, _props.path)
      .then((response) => {
        const { importedFiles, notImportedFiles } = response;
        if (_props.sucessFunction) {
          _props.sucessFunction(response);
        }
        setHint(
          <>
            <div className="success-icon">
              <BdrPictogram pictogram={svgPictogram} />
            </div>
            {importedFiles.length} Dateien wurden erfolgreich verarbeitet.
            <br />
            {notImportedFiles.length > 0 && (
              <>
                {notImportedFiles.length} Dateien konnten nicht verarbeitet
                werden:
                {notImportedFiles.map((file, i) => (
                  <VerySmallText key={i}>{file.FileName}</VerySmallText>
                ))}
              </>
            )}
          </>
        );
        setFiles([]);
      })
      .catch((error) => {
        setHint(<Error error={error} />);
      })
      .finally(() => {
        setIsUploading(false);
        if (_props.onUpload) _props.onUpload();
      });
  };

  const allowdMimeType = [];
  _props.types.forEach((type) => {
    allowdMimeType.push(...TYPES_TO_MIME[type]);
  });

  return (
    <div
      slot="card-content"
      id="upload-area"
      className={`dragdrop-${_props.size}`}
    >
      <Dropzone
        onDrop={onDrop}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        accept={{
          "": allowdMimeType,
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps()}
              id="drag-area"
              className={dragActive ? "highlight" : ""}
            >
              <input {...getInputProps()} />
              <H5>{hint}</H5>
            </div>
          </section>
        )}
      </Dropzone>
      <br />
      {files.length > 0 && !isUploading && (
        <div>
          <BdrButton text="Upload" type="submit" onClick={() => sendFiles()} />
        </div>
      )}
    </div>
  );
};
export default UploadComponent;
