import { BdrTypo } from "@style-guide/web-components-react";
import React, { useEffect, useState } from "react";
import { formatValue } from "../../lib/text";
import { usePlainRules } from "../../hooks/useApi";
import { ruleIsValidForDevice } from "../Rules";
import { Loading } from "../Helpers";

export const DEVICE_MAPPINGS_IMPORTANT = {
  simago_version: "Version Simago",
  hd_client_version: "Version HDClient",
  comment: "Bemerkung",
  image: "Image",
  hardware_version: "Version",
  mac_address: "MAC-Adresse",
  category: "Kategorie",
  warranty_from: "Gewährleistung von",
  warranty_to: "Gewährleistung bis",
};

export const DEVICE_MAPPINGS = {
  ...DEVICE_MAPPINGS_IMPORTANT,
  serial_number: "Seriennummer",
  state: "Status/Ort",
  location: "SAP Standort",
  authorities: "BHKZ",
};

const displayLocation = (position, key, from) => {
  if (!position[key] || position[key].length === 0) {
    return `Kein Standort ${from} erfasst`;
  }
  if (typeof position[key] === "string") {
    return `Standort ${from}: ${position[key]}`;
  }
  if (position[key].length === 1) {
    if (position[key][0].length === 0) {
      return `Kein Standort ${from} erfasst`;
    }

    return `Standort ${from}: ${position[key][0]}`;
  }
  return `Standorte ${from}: ${position[key].join(", ")}`;
};

const DeviceDetail = ({ device }) => {
  const { data, isLoading } = usePlainRules();
  const [detailsAndRules, setDetailsAndRules] = useState(
    DEVICE_MAPPINGS_IMPORTANT
  );

  useEffect(() => {
    if (data?.rules) {
      let details = { ...DEVICE_MAPPINGS_IMPORTANT };
      data.rules.forEach((rule) => {
        device[`rule_${rule.id}`] = ruleIsValidForDevice(rule, device);
        details[`rule_${rule.id}`] = rule.name;
      });
      setDetailsAndRules(details);
    }
  }, [data, device]);

  return (
    <div>
      <div className="table-row">
        <div className="table-cell pr-4">
          <BdrTypo type="body-medium">Standort</BdrTypo>
        </div>
        <div className="table-cell">
          <BdrTypo type="body-medium">
            <div>
              {displayLocation(device.position, "sap_locations", "im SAP")}
            </div>
            <div>
              {displayLocation(device.position, "movement_location", "von L&D")}
            </div>
          </BdrTypo>
        </div>
      </div>
      {Object.keys(detailsAndRules).map((key) => (
        <div key={key} className="table-row">
          <div className="table-cell pr-4">
            <BdrTypo type="body-medium">{detailsAndRules[key]}</BdrTypo>
          </div>
          <div className="table-cell">
            <BdrTypo type="body-medium">{formatValue(device[key])}</BdrTypo>
          </div>
        </div>
      ))}
      {isLoading && <Loading text="Lade Regeln..." />}
    </div>
  );
};
export default DeviceDetail;
