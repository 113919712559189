export const anythingIsNumber = (value) => {
  if (typeof value === "number") return true;
  if (typeof value === "string") {
    return !isNaN(value);
  }
  return false;
};

export const anythingIsFloat = (value) => {
  if (typeof value === "number") return true;
  if (typeof value === "string") {
    return !isNaN(parseFloat(value)) && value.includes(".");
  }
  return false;
}

export const toGermanNumber = (value, unit = "") => {
  unit = " " + unit;
  if (!anythingIsNumber(value)) return value;
  const options = {};
  if (unit === " €") {
    options.currency = "EUR";
    options.style = "currency";
    unit = "";
  }
  return value.toLocaleString("de-DE", options) + unit;
};

export const toGermanInteger = (value, unit = "") => {
  unit = " " + unit;
  if (!anythingIsNumber(value)) return value;
  const options = {};
  return Math.round(value).toLocaleString("de-DE", options) + unit;
}

export const revertGermanNumber = (value) => {
  if (typeof value === "string") {
    return value.replace(".", "").replace(",", ".");
  }
  return value;
};

export const rateFormater = (value) => {
  if (!anythingIsFloat(value)) return rateFormater(0);
  return toGermanNumber(Math.round(value*10000)/100,'%');
}

export const markZeroRed = (value) => {
  if (!value) return <span style={{
    color: 'red',
    fontWeight: 'bold',
  }}>0</span>;
  return value;
}

export const euroFormater = (value) => toGermanNumber(value, "€");