import React from "react";
import RuleForm from "../components/form/forms/RuleForm";
import { useNavigate } from "react-router-dom";
import { createRule } from "../hooks/useApi";
import { rulesLink } from "../lib/links";
import Button from "../components/form/Button";

const NewRule = () => { 
    const [rule, setRule] = React.useState({});
    const navigate = useNavigate();
    const reset = () => {
      navigate(rulesLink);
    };
    const submit = async () => {
      await createRule(rule);
      reset();
    };
    const Buttons = (
      <div className="button-group-right">
        <Button
          onClick={reset}
          text="Abbrechen"
          type="button"
          variant="secondary"
        />
        <Button onClick={submit} text="Speichern" type="submit" />
      </div>
    );
    return (
        <div className="pages device-card editable">
        {Buttons}
            <RuleForm rule={rule} setRule={setRule} />
        </div>
    );
};

export default NewRule;