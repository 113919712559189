const ERROR_CODES = {
  GENERIC_ERROR: "Allgemeiner Fehler",
  AT_LEAST_ONE_ADMIN_NEEDED: "Es muss mindestens ein Admin vorhanden sein",
  USER_NOT_FOUND: "Benutzer nicht gefunden",
  UNAUTHORIZED: "Sie haben keine Berechtigung",
  ACCESS_DENIED: "Zugriff verweigert",
  NOT_FOUND: "Nicht gefunden",
  PASSWORD_TOO_SHORT: "Passwort zu kurz",
  PASSWORD_NO_UPPERCASE:
    "Passwort muss mindestens einen Großbuchstaben enthalten",
  PASSWORD_NO_LOWERCASE:
    "Passwort muss mindestens einen Kleinbuchstaben enthalten",
  PASSWORD_NO_NUMBER: "Passwort muss mindestens eine Zahl enthalten",
  OLD_PASSWORD_WRONG: "Aktuelles Passwort ist falsch",
  DUPLICATE_ENTRY: "Eintrag bereits vorhanden",
  LOGIN_ERROR: "Falsche Kombinantion aus Benutzername und Passwort",
  BRUTE_FORCE_PROTECTION: "Zu viele Anfragen",
};
export default ERROR_CODES;
