import React, { useState } from "react";
import CHART from "@style-guide/assets/icons/chart_sqaure_01.svg";
import TABLE from "@style-guide/assets/icons/align_justify.svg";
import { BdrPictogram, BdrTypo } from "@style-guide/web-components-react";

import "./TableChart.css";
import Table from "../table/Table";
import { BarAreaChart, StackedBarChart } from "../stats/BarCharts";
import { germanMonth } from "../../lib/date";

export const TableChartSwitch = ({ onChange, state }) => {
  const [chartPic, setChartPic] = useState();
  const [tablePic, setTablePic] = useState();
  fetch(CHART)
    .then((r) => r.text())
    .then((text) => {
      setChartPic(text);
    });
  fetch(TABLE)
    .then((r) => r.text())
    .then((text) => {
      setTablePic(text);
    });
  return (
    <div className="table-chart-switch" onClick={onChange}>
      {state === "table" && (
        <BdrPictogram
          pictogram={chartPic}
          alternativeText="Zur Grafik umschalten"
        />
      )}
      {state === "chart" && (
        <BdrPictogram
          pictogram={tablePic}
          alternativeText="Zur Tabelle umschalten"
        />
      )}
    </div>
  );
};

const TableChart = ({ table, chart }) => {
  const [state, setState] = useState("chart");

  return (
    <div className="table-chart">
      <TableChartSwitch
        onChange={() => {
          setState(state === "chart" ? "table" : "chart");
        }}
        state={state}
      />
      {state === "table" && table}
      {state === "chart" && chart}
    </div>
  );
};

export const TableChartMonth = ({
  data,
  title,
  noDataText,
  groupKey = "authority_code",
  sumField = "amount",
}) => {
  const orders = [];
  const stacks = [];
  data.forEach((order) => {
    const groupKeyVal = order[groupKey];
    const index = orders.findIndex((o) => o.order_month === order.order_month);
    if (index === -1) {
      orders.push({
        order_month: order.order_month,
        [groupKeyVal]: order[sumField],
        sum: order[sumField],
      });
    } else {
      orders[index][groupKeyVal] = order[sumField];
      orders[index].sum += order[sumField];
    }
    if (!stacks.includes(groupKeyVal)) {
      stacks.push(groupKeyVal);
    }
  });

  const addToHeader = [];
  if (stacks.length > 1) {
    addToHeader.push({ id: "sum", name: "Summe" });
  }

  return (
    <>
      <BdrTypo text={title} type="headline-2" _id="order-headline" />
      {data.length === 0 && <BdrTypo text={noDataText} type="body-medium" />}
      {orders.length > 0 && (
        <TableChart
          chart={
            <StackedBarChart
              data={orders}
              stacks={stacks}
              width={950}
              primaryDataKey={"order_month"}
            />
          }
          table={
            <Table
              data={orders}
              _id="order-table"
              headers={[
                {
                  id: "order_month",
                  name: "Monat",
                  customFormatter: germanMonth,
                },
                ...stacks.map((stack) => ({
                  id: stack,
                  name: stack,
                })),
                ...addToHeader,
              ]}
              withSums={true}
            />
          }
        />
      )}
    </>
  );
};

export const LineAreaChartMonth = ({
  data,
  title,
  noDataText,
  barKey,
  areaKey,
  primaryDataKey,
  headers,
}) => {
  return (
    <>
      <BdrTypo text={title} type="headline-2" _id="order-headline" />
      {data.length === 0 && <BdrTypo text={noDataText} type="body-medium" />}
      {data.length > 0 && (
        <TableChart
          chart={
            <BarAreaChart
              data={data}
              width={950}
              primaryDataKey={primaryDataKey}
              barKey={barKey}
              areaKey={areaKey}
              headers={headers}
            />
          }
          table={
            <Table
              _id="order-value-table"
              data={data}
              headers={headers}
              withSums={true}
            />
          }
        />
      )}
    </>
  );
};

export default TableChart;
