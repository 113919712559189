import { germanDay } from "../date";
import { salesLinkFormatter } from "../links";

export const salesTableHeaders = [
  // { id: "id", name: "ID" },
  {
    id: "number",
    name: "Faktura",
    customFormatter: (value,{id,source}) => source ==='CSV' ? value : salesLinkFormatter(id,value),
    className: "align-right",
  },
  { id: "pos", name: "Pos" },
  { id: "invoice_type", name: "FkArt" },
  { id: "net", name: "Netto" },
  { id: "name", name: "Kunde" },
  { id: "material", name: "Material" },
  { id: "description", name: "Bezeichnung" },
  { id: "quantity", name: "Fakt.Menge" },
  { id: "me", name: "ME" },
  { id: "state", name: "Bez." },
  { id: "invoiced_at", name: "Fakturadatum", customFormatter: germanDay },
];

export const salesSearchMapping = {
  number: "Faktura",
  pos: "Pos",
  invoice_type: "FkArt",
  net: "Netto",
  name: "Kunde",
  material: "Material",
  description: "Bezeichnung",
  quantity: "Fakt.Menge",
  me: "ME",
  state: "Bez.",
  invoiced_at: "Fakturadatum",
};
