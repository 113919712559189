import InfoButton from "../../components/screen/Info";
import { germanDay } from "../date";
import { jiraLinkFormatter } from "../links";


const detailText = <>
<br />
<br />
Es werden auch verschrottete Geräte berücksichtigt. <br />
<ul className="list-disc">
  <li>
    Um nur aktive Geräte zu sehen, kann nach dem Link der Filter
    'Kategorie: Behörde' genutzt werden.
  </li>
  <li>
    Um nur verschrottete Geräte zu sehen, kann nach dem Link der
    Filter 'Status/Ort: Verschrottet' genutzt werden.
  </li>
</ul>
</>

export const rulesHeaders = [
  {
    id: "name",
    name: "Name",
  },
  {
    id: "positive_number_of_devices",
    name: (
      <span style={{ whiteSpace: "nowrap" }}>
        zutreffend
        <InfoButton>
          Anzahl Geräte, die eine der <b>betroffenen Versionen</b> UND einen{" "}
          <b>Warenausgang</b> nach "Gültig ab" haben.
          {detailText}
        </InfoButton>
      </span>
    ),
  },
  {
    id: "negative_number_of_devices",
    name: (
      <>
        nicht{" "}
        <span style={{ whiteSpace: "nowrap" }}>
          zutreffend
          <InfoButton>
            Anzahl Geräte, die eine der <b>betroffenen Versionen</b>, aber nur{" "}
            <b>Warenausgänge</b> vor "Gültig ab" haben.
            {detailText}
          </InfoButton>
        </span>
      </>
    ),
  },
  {
    id: "valid_from",
    name: (
      <>
        Gültig ab
        <InfoButton>
          Bezieht sich nur auf Geräte, die nach diesem Zeitpunkt einen
          Warenausgang hatten.
        </InfoButton>
      </>
    ),
    customFormatter: germanDay,
  },
  {
    id: "affected_versions",
    name: "Betroffene Versionen",
    customFormatter: (value) => (value || []).sort().join(", "),
  },
  {
    id: "description",
    name: "Beschreibung",
  },
  {
    id: "jira_key",
    name: "Link",
    customFormatter: jiraLinkFormatter,
  },
];
