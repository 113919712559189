import { BdrTypo } from "@style-guide/web-components-react";
import React from "react";
import Input from "./Input";
import { germanDay } from "../../lib/date";
import { nl2br } from "../../lib/text";

export const EditableField = ({
  displayName,
  value,
  type = "input",
  onChange,
  editMode = false,
  input,
}) => {
  input = input || <Input value={value} type={type} onChange={onChange} />;
  return (
    <PlainEditableField
      left={displayName}
      right={
        <>
          {editMode && input}
          {!editMode && displayField(value, type)}
        </>
      }
    />
  );
};

export const PlainEditableField = ({ left, right }) => {
  return (
    <div className="table-row">
      <div className="table-cell pr-4">
        <BdrTypo type="body-medium">{left}</BdrTypo>
      </div>
      <div className="table-cell">{right}</div>
    </div>
  );
};

export const MultiCheckboxes = ({
  displayName,
  value = [],
  possibleValues,
  onChange,
  editMode = false,
}) => {
  value = value || [];
  return (
    <div className="table-row">
      <div className="table-cell pr-4">
        <BdrTypo type="body-medium">{displayName}</BdrTypo>
      </div>
      {editMode && (
        <div className="table-cell flex w-full">
          {possibleValues.map((possibleValue) => (
            <label className="flex-1" key={possibleValue}>
              <input
                className="mr-2"
                type="checkbox"
                checked={value.includes(possibleValue)}
                onChange={(e) => {
                  const newValue = e.target.checked
                    ? [...value, possibleValue]
                    : value.filter((v) => v !== possibleValue);
                  onChange({ target: { value: newValue } });
                }}
              />
              {possibleValue}
            </label>
          ))}
        </div>
      )}
      {!editMode && (
        <div className="table-cell">
          {value.map((value) => (
            <BdrTypo type="body-small" key={value}>
              {value}
            </BdrTypo>
          ))}
        </div>
      )}
    </div>
  );
};

export const displayField = (value, type = "input") => {
  if (type === "date") {
    return germanDay(value);
  }
  if (type === "textarea") {
    return nl2br(value);
  }
  return value;
};
