import { BdrTypo } from "@style-guide/web-components-react";
import React from "react";

const Headline = ({ children, size, id }) => (
  <BdrTypo type={`headline-${size}`} _id={id} >{children}</BdrTypo>
);

const Text = ({ children, size }) => (
  <BdrTypo type={`body-${size}`} >{children}</BdrTypo>
);

export const H1 = ({ children, id }) => (
  <Headline size="1" id={id}>
    {children}
  </Headline>
);
export const H2 = ({ children, id }) => (
  <Headline size="2" id={id}>
    {children}
  </Headline>
);
export const H3 = ({ children, id }) => (
  <Headline size="3" id={id}>
    {children}
  </Headline>
);
export const H4 = ({ children, id }) => (
  <Headline size="4" id={id}>
    {children}
  </Headline>
);
export const H5 = ({ children, id }) => (
  <Headline size="5" id={id}>
    {children}
  </Headline>
);
export const BodyText = ({ children }) => <Text size="medium">{children}</Text>;
export const VerySmallText = ({ children }) => (
  <Text size="bold-x-small">{children}</Text>
);
