import React, { useState } from "react";
import SaleForm from "../components/form/forms/SaleForm";
import { useNavigate } from "react-router-dom";
import { createSale } from "../hooks/useApi";
import Button from "../components/form/Button";
import { ErrorMessageBox } from "../components/Helpers";
import { handleErrorResponse } from "../lib/errors";
import { salesLink } from "../lib/links";

const NewSale = () => {
  const [errorText, setErrorText] = useState(false);
  const [valid, setValid] = useState(false);
  const [sale, setSale] = useState({
    number: "",
    pos: "",
    invoice_type: "",
    net: 0,
    name: "",
    material: "",
    description: "",
    quantity: "",
    me: "",
    state: "",
    invoiced_at: new Date().toISOString().split("T")[0],
  });
  const navigate = useNavigate();
  const reset = () => {
    navigate('/finance');
  };
  const submit = async () => {
    try {
      const date = new Date(sale.invoiced_at);
      date.setHours(date.getHours() + 5);
      await createSale({
        ...sale,
        invoiced_at: date.toISOString(),
      });
      navigate(salesLink);
    } catch (error) {
      const errorMessage = handleErrorResponse(error.message);
      setErrorText(errorMessage);
    }
  };

  const onChange = (sale) => {
    const valid = sale.number && sale.invoiced_at;
    setValid(valid);
  };
  const Buttons = (
    <div className="button-group-right">
      <Button
        onClick={reset}
        text="Abbrechen"
        type="button"
        variant="secondary"
      />
      <Button onClick={submit} text="Speichern" type="submit" disabled={!valid} />
    </div>
  );
  return (
    <div className="pages device-card editable">
      {Buttons}
      {<ErrorMessageBox error={errorText} />}
      <SaleForm sale={sale} setSale={setSale} onChange={onChange} />
    </div>
  );
};

export default NewSale;
