import React from "react";
import { H1 } from "../components/screen/Text";
import Rules from "../components/Rules";
import Users from "../components/Users";
import { RenderForRole } from "../lib/token";
import User from "../components/User";
import Info from "../components/Info";

const Settings = () => {
  return (
    <>
      <RenderForRole role="Admin">
        <div className="pages">
          <H1>Regeln</H1>
          <Rules />
        </div>
        <div className="pages">
          <H1>Nutzer</H1>
          <Users />
        </div>
      </RenderForRole>
      <div className="pages device-card editable">
        <H1>Eigenes Profil</H1>
        <User />
      </div>
      <div className="pages device-card editable">
        <H1>Info</H1>
        <Info/>
      </div>
    </>
  );
};

export default Settings;
