import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  changeContract,
  deleteContract,
  directContract,
  useContract,
} from "../hooks/useApi";
import { Error, Loading } from "../components/Helpers";

import "./Contract.css";
import ContractForm from "../components/form/forms/ContractForm";
import UploadComponent from "../components/UploadComponent";
import ContractAttachment from "../components/ContractAttachment";
import { EditButtonGroup } from "../components/form/Editbuttons";
import { BodyText } from "../components/screen/Text";

const Contract = () => {
  let { id } = useParams();

  const { data, error, isLoading } = useContract(id);
  const [editMode, setEditMode] = useState(false);
  const [contract, setContract] = useState(data);

  const navigate = useNavigate();

  const reload = async () => {
    const _data = await directContract(contract);
    if (!_data) return;
    setContract(_data);
  };

  useEffect(() => {
    if (!data) return;
    setContract({ ...data });
  }, [data]);

  if (isLoading) return <Loading />;
  if (error) return <Error error={error} />;

  const cancel = () => {
    setContract({ ...data });
    setEditMode(false);
  };
  const submit = () => {
    changeContract(contract);
    setEditMode(false);
  };
  const del = () => {
    // confirm

    deleteContract(contract);

    const isOK = window.confirm("Vertrag löschen?");
    if (!isOK) return;
    navigate(-1);
  };
  return (
    <div className="pages device-card editable">
      <EditButtonGroup
        editMode={editMode}
        setEditMode={setEditMode}
        save={submit}
        cancel={cancel}
        del={del}
      />
      <ContractForm
        contract={contract}
        editMode={editMode}
        setContract={setContract}
      />
      <div className="table-row">
        <div className="table-cell pr-4">
          <BodyText>Angehängte Dateien</BodyText>
        </div>
        <div className="table-cell" key={JSON.stringify(contract.attached_files)}>
          <ContractAttachment contract={contract} onDelete={reload} />
          <UploadComponent
            hint="Dateien zum Vertrag anhängen"
            size="small"
            path={`contracts/${contract.id}/upload`}
            types={["pdf", "jpg", "png"]}
            onUpload={() => {
              reload();
            }}
          />
        </div>
      </div>

    </div>
  );
};

export default Contract;
