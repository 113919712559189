import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RuleForm from "../components/form/forms/RuleForm";
import { deleteRule, updateRule, useRule } from "../hooks/useApi";
import { rulesLink } from "../lib/links";
import { Error, Loading } from "../components/Helpers";
import { EditButtonGroup } from "../components/form/Editbuttons";

const EditRule = () => {
  const { id } = useParams();
  const { data, error, isLoading } = useRule(id);
  const [rule, setRule] = useState({});
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (data && data.rule) {
      setRule(data.rule);
    }
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  const reset = () => {
    navigate(rulesLink);
  };
  const submit = async () => {
    await updateRule(rule);
    reset();
  };
  const remove = async () => {
    await deleteRule(rule);
    reset();
  };
  return (
    <div className="pages device-card editable">
      <EditButtonGroup
        editMode={editMode}
        setEditMode={setEditMode}
        cancel={reset}
        save={submit}
        del={remove}
      />
      <RuleForm rule={rule} setRule={setRule} editMode={editMode} />
    </div>
  );
};

export default EditRule;
