import React from "react";
import Table from "../table/Table";
import {
  commonMixedUpsTableHeaders,
  mixedPlacesTableHeaders,
} from "../../lib/constants/mixedUps";
import { BdrTypo } from "@style-guide/web-components-react";
import TableToCSV, { csvName } from "../TableToCSV";

const DevicesWithMoreThanOneOrderPlace = ({
  devices_with_more_than_one_order_place,
  onChange,
}) => {
  const headers = [
    ...mixedPlacesTableHeaders,
    ...commonMixedUpsTableHeaders,
  ];
  return (
    <div className="content">
      <BdrTypo
        text={`SSTs mit mehreren Bestellorten in diesem Jahr (${devices_with_more_than_one_order_place.length})`}
        type="headline-2"
      />
      <Table headers={headers} data={devices_with_more_than_one_order_place} />
      <TableToCSV
        headers={mixedPlacesTableHeaders}
        data={devices_with_more_than_one_order_place}
        filename={csvName("sst_mit_mehreren_bestellorten")}
        pullRight={true}
      />
    </div>
  );
};

export default DevicesWithMoreThanOneOrderPlace;
