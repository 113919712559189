import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Navbar.css";
import { Header } from "./BdrHeader";
import { BdrHeaderMenuItem } from "@style-guide/web-components-react";
import { useWindowDimensions } from "../hooks/window";
import { getToken, removeToken } from "../lib/token";

const Navbar = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = React.useState(
    getToken() ? true : false
  );
  const { width } = useWindowDimensions();

  let navigationItems = [
    {
      text: "Login",
      clickHandler: () => {
        navigate("/login");
      },
    },
  ];

  if (isAuthenticated) {
    let collapsableItems = [
      {
        text: "Dashboard",
        location: "/",
        clickHandler: () => {
          navigate("/");
        },
      },
      {
        text: "SSTs",
        location: "/devices",
        clickHandler: () => {
          navigate("/devices");
        },
      },
      {
        text: "Reparaturen",
        location: "/repairs",
        clickHandler: () => {
          navigate("/repairs");
        },
      },
      {
        text: "Finanzen",
        location: "/finance",
        clickHandler: () => {
          navigate("/finance");
        },
      },
      {
        text: "Verträge",
        location: "/contracts",
        clickHandler: () => {
          navigate("/contracts");
        },
      },
      {
        text: "Upload",
        location: "/upload",
        clickHandler: () => {
          navigate("/upload");
        },
      },
      {
        text: "Konflikte",
        location: "/mixedup",
        clickHandler: () => {
          navigate("/mixedup");
        },
      },
      {
        text:"Verwaltung",
        location: "/settings",
        clickHandler: () => {
          navigate("/settings");
        }
      }
    ];

    let take = collapsableItems.length;

    if (width < 1350) {
      take = 3;
    }
    if (width < 1000) {
      take = 2;
    }
    if (width < 800) {
      take = 1;
    }
    const more = collapsableItems.splice(take);
    if (more.length > 0) {
      collapsableItems.push({
        text: "Mehr",
        navigationItems: more,
      });
    }

    navigationItems = [
      ...collapsableItems,
      {
        text: "Logout",
        clickHandler: () => {
          removeToken();
          setIsAuthenticated(false);
          navigate("/login");
        },
      },
    ];
  }

  const isActive = (item) => {
    if (Array.isArray(item.location)) {
      return item.location.find((loc) => locationMatch(loc));
    }
    return locationMatch(item.location);
  };

  const locationMatch = (loc) => {
    const currentPath = location.pathname
      .replace("/ui", "/")
      .replace("//", "/");
    if (loc === "/") {
      return currentPath === loc;
    }
    return currentPath.startsWith(loc);
  };

  return (
    <Header text="SST Reporting">
      <nav slot="header-suffix" className="__navigation">
        <ul className="__list">
          {navigationItems.map((item, index) => {
            return (
              <BdrHeaderMenuItem
                data-component="HeaderMenuItem"
                key={index}
                text={item.text}
                onClick={item.clickHandler}
                selected={isActive(item)}
                navigationItems={item.navigationItems}
              />
            );
          })}
        </ul>
      </nav>
    </Header>
  );
};

export default Navbar;
