import React, { useEffect, useState } from "react";
import { useRepairs } from "../hooks/useApi";
import { sortByKey } from "../lib/sort";
import { TagLink } from "../lib/links";
import { Error, Loading } from "../components/Helpers";
import Table from "../components/table/Table";
import { repairsTableHeaders } from "../lib/constants/repairs";
import SearchBar from "../components/SearchBar";
import { extractTags } from "../lib/text";
import { BdrTypo } from "@style-guide/web-components-react";

const Repairs = () => {
  const { data, isLoading, error } = useRepairs();
  const [repairs, setRepairs] = useState(data?.repairs);
  const [hasFilters, setHasFilters] = useState(false);
  const [mostTags, setMostTags] = useState(0);
  const [hashTagList, setHashTagList] = useState([]);

  const sortBy = (key, sortDirection = "ASC") => {
    setRepairs(sortByKey(repairs, key, sortDirection));
  };

  const onSearch = (filters) => {
    if(!Array.isArray(filters)){
      return
    }
    const filtered = data.repairs.filter((repair) =>
      filters.every((filter) => JSON.stringify(repair).includes(filter))
    );
    setHasFilters(filters.length > 0);
    setRepairs(filtered);
  };

  useEffect(() => {
    if (
      data.repairs &&
      Array.isArray(data.repairs) &&
      data.repairs.length > 0
    ) {
      const sorted = sortByKey(data.repairs, "serial_number");

      setRepairs(sorted);
      const _mostTags = data.repairs.reduce((acc, { reason, comment }) => {
        extractTags(reason).forEach((tag) => {
          acc[tag] = acc[tag] ? acc[tag] + 1 : 1;
        });
        extractTags(comment).forEach((tag) => {
          acc[tag] = acc[tag] ? acc[tag] + 1 : 1;
        });
        return acc;
      }, {});
      setHashTagList(Object.keys(_mostTags));
      const sortedTags = Object.keys(_mostTags)
        .sort((a, b) => _mostTags[b] - _mostTags[a])
        .slice(0, 10);

      setMostTags(sortedTags);
    }
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }
  return (
    <>
      {repairs && (
        <div className="pages">
          <SearchBar
            suggestionList={hashTagList}
            hashTagOnly={true}
            onFilter={onSearch}
            placeholder="Suche nach Hashtags..."
          />
          <BdrTypo type="body-x-small">
            Meistgenutzte Tags:{" "}
            {mostTags.map((tag) => (
              <TagLink key={tag} tag={tag} text={tag} />
            ))}
          </BdrTypo>
          <Table data={repairs} onSort={sortBy} headers={repairsTableHeaders} />
          {hasFilters &&
            repairs.length === 0 &&
            "Keine Reparaturen entsprechen den Filtern."}
          {hasFilters &&
            repairs.length === 1 &&
            `Eine Reparatur (von ${data.repairs.length} Reparaturen) entspricht den Filtern.`}
          {hasFilters && repairs.length > 1 && (
            <div>
              {repairs.length} von {data.repairs.length} Reparaturen entsprechen
              den Filtern.
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Repairs;
