import React from "react";
import { useParams } from "react-router-dom";
import { useDevice } from "../hooks/useApi";
import { Error, Loading } from "../components/Helpers";
import DeviceCard from "../components/device/DeviceCard";

const Device = () => {
  let { id } = useParams();

  const { data, error, isLoading } = useDevice(id);

  if (isLoading) return <Loading />;
  if (error) return <Error error={error} />;

  return <DeviceCard device={data.device} />;
};

export default Device;
