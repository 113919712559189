import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { BdrPictogram } from "@style-guide/web-components-react";
import DOWNLOAD from "@style-guide/assets/icons/download.svg";

import "./TableToCSV.css";

const TableToCSV = ({
  data,
  headers,
  title = "CSV",
  altTitle,
  filename = "download.csv",
  className = "download-csv",
  pullRight = false,
}) => {
  const [downloadPic, setDownloadPic] = useState();
  className += pullRight ? " download-csv-right" : "";
  useEffect(() => {
    fetch(DOWNLOAD)
      .then((r) => r.text())
      .then((text) => {
        setDownloadPic(text);
      });
  }, []);
  const csvHeaders = headers.map((h) => ({ label: h.name, key: h.id }));
  const csvData = data.map((d) => {
    const row = {};
    headers.forEach((h) => {
      row[h.id] = d[h.id];
    });
    return row;
  });
  if (!altTitle) {
    altTitle = `Download als CSV-Datei: ${filename}`;
  }
  return (
    <CSVLink
      headers={csvHeaders}
      data={csvData}
      filename={filename}
      className={className}
      title={altTitle}
    >
      <BdrPictogram pictogram={downloadPic} alternativeText={altTitle} />
      {title}
    </CSVLink>
  );
};

export const csvName = (name) => {
  return `${name.replace(/ /g, "_").toLowerCase()}_${
    new Date().toISOString().split("T")[0]
  }.csv`;
};

export default TableToCSV;
