import { germanDay } from "../date";
import { ConflictLink, DevicesFilterLink, deviceLinkFormatter } from "../links";

export const mixedPlacesTableHeaders = [
  {
    id: "device_id",
    name: "Seriennummer SST",
    customFormatter: deviceLinkFormatter,
  },
  {
    id: "count",
    name: "Anzahl Standorte",
  },
  {
    id: "locations",
    name: "Bestellorte im SAP",
    customFormatter: (locations) => (locations || []).join(", "),
  },
  {
    id: "last_moved_out_location",
    name: "Lieferort laut L&D",
  },
  {
    id: "last_moved_out_date",
    name: "Lieferdatum",
    customFormatter: germanDay,
  },
];

export const mixedAuthoritiesTableHeaders = [
  {
    id: "authority_id",
    name: "BHKZ",
    customFormatter: (value) => DevicesFilterLink(value, `authorities:${value}`),
  },
  {
    id: "last_order_date",
    name: "Letzte Transaktion",
    customFormatter: germanDay,
  },
];

export const mixedSAPOnlyTableHeaders = [
  ...mixedPlacesTableHeaders.filter(({ id }) =>
    ["device_id", "locations"].includes(id)
  ),
  {
    id: "sales_id",
    name: "Vertriebs-ID",
  },
  {
    id: "last_order_month",
    name: "Letze Transaktion",
  },
];

export const masterFileHeaders = [
  {
    id: "contract_id",
    name: "Warenempfänger",
    customFormatter: (value) => value || <i>Kein Eintrag</i>,
  },
  {
    id: "authority_id",
    name: "BHKZ",
    customFormatter: (value) => value || <i>Kein Eintrag</i>,
  },
  {
    id: "sales_id",
    name: "Vertriebs-ID",
    customFormatter: (value) => value || <i>Kein Eintrag</i>,
  },
];

export const devicesWithoutOrdersInLastOrderMonthTableHeaders = [
  {
    id: "device_id",
    name: "Seriennummer SST",
    customFormatter: deviceLinkFormatter,
  },
  {
    id: "last_order_month",
    name: "Letzte Transaktion",
  },
];

export const commonMixedUpsTableHeaders = [
  {
    id: "state_id",
    name: "Status",
    customFormatter: ConflictLink,
  },
  {
    id: "assigned_to",
    name: "Zugewiesen an",
  },
  {
    id: "comment",
    name: "Kommentar",
  },
];

export const conflictStates = [
  "Offen",
  "In Bearbeitung",
  "Gelöst",
  "Automatisch gelöst",
];

export const MIXUP_MAPPINGS = {
  active: "Aktiv",
  state: "Status",
  assigned_to: "Zugewiesen an",
  comment: "Kommentar",
  device_id: "Seriennummer SST",
  authority_id: "BHKZ",
  locations: "Bestellorte im SAP",
  last_moved_out_location: "Lieferort laut L&D",
};
