import React from "react";
import RepairsStats from "./Repairs";
import StatsByVersion from "./StatsByVersion";
import ContractsByState from "./ContractsByState";
import ExchangeRate from "./ExchangeRate";

const Details = ({ selected }) => {
  if (selected === "devices_in_repair") {
    return <RepairsStats />;
  }
  if (selected === "stats_by_version") {
    return (
      <>
        <ExchangeRate />
        <StatsByVersion />
      </>
    );
  }
  if (selected === "contracts_by_state") {
    return <ContractsByState />;
  }

  return null;
};

export default Details;
