import React, { useEffect, useState } from "react";

import { useDevices, usePlainRules } from "../hooks/useApi";
import SearchBar from "../components/SearchBar";
import { sortByKey } from "../lib/sort";
import { Error, Loading } from "../components/Helpers";
import Table from "../components/table/Table";
import { devicesTableHeaders } from "../lib/constants/devices";
import { DEVICE_MAPPINGS } from "../components/device/DeviceDetail";
import { formatValue, reversValue } from "../lib/text";
import TableToCSV, { csvName } from "../components/TableToCSV";
import { ruleIsValidForDevice } from "../components/Rules";

const Devices = () => {
  const { data, isLoading, error } = useDevices();
  const { data: ruleData } = usePlainRules();
  const [allLoadedDevices, setAllLoadedDevices] = useState([]);
  const [devices, setDevices] = useState(data?.devices);
  const [showAll, setShowAll] = useState(false);
  const [filterAndRules, setFilterAndRules] = useState(DEVICE_MAPPINGS)
  const [hasFilters, setHasFilters] = useState(false);

  const onSearch = (_filters = []) => {
    if (!Array.isArray(_filters)) {
      return;
    }
    const filters = _filters.map((filter) => {
      const [key, value] = filter.split(":", 2);
      let originalKey = Object.keys(filterAndRules).find(
        (k) => filterAndRules[k] === key.trim()
      );

      return { key: originalKey, value: reversValue(value.trim()) };
    });
    setHasFilters(_filters.length > 0);
    console.log("filters", filters);
    const filtered = allLoadedDevices.filter((device) =>
      filters.every((filter) => {
        if (Array.isArray(device[filter.key])) {
          return device[filter.key].includes(filter.value);
        }
        return formatValue(device[filter.key]) === filter.value;
      })
    );
    setDevices(filtered);
  };

  const sortBy = (key, sortDirection = "ASC") => {
    setDevices(sortByKey(devices, key, sortDirection));
    return false;
  };

  useEffect(() => {
    if (
      data.devices &&
      Array.isArray(data.devices) &&
      data.devices.length > 0
      &&
      ruleData.rules &&
      Array.isArray(ruleData.rules)
    ) {
      const _devices = data.devices
      const _filtersAndRules = {
        ...DEVICE_MAPPINGS,
      }
      ruleData.rules.forEach((rule) => {
        _devices.forEach((device) => {
          device[`rule_${rule.id}`] = ruleIsValidForDevice(rule, device);
        });
        _filtersAndRules[`rule_${rule.id}`] = rule.name;
      });
      _devices.forEach((device) => {
        if(!device.warranty_from){
          device.warranty_from = "Keine Angabe"
        }
        if(!device.warranty_to){
          device.warranty_to = "Keine Angabe"
        }
      });
      setDevices(_devices);
      setAllLoadedDevices(_devices);
      setFilterAndRules(_filtersAndRules)
    }
  }, [data,ruleData]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  console.log("deveices", devices);

  return (
    <>
      {devices && (
        <div className="pages">
          <SearchBar
            suggestionList={devices}
            onFilter={onSearch}
            mapKeyValues={filterAndRules}
            placeholder="Suche nach SST (Seriennummer, Eigenschaft,..)"
          />

          <TableToCSV
            headers={devicesTableHeaders}
            data={devices}
            filename={csvName("sst_liste")}
            pullRight={true}
          />
          <div>
            <Table
              data={showAll ? devices : devices.slice(0, 50)}
              onSort={sortBy}
              headers={devicesTableHeaders}
            />
            {!showAll && devices.length > 50 && (
              <>
                {" "}
                Gefilterete Anzeige 50 von {devices.length} Zeilen{" "}
                <a
                  className="bdr-button bdr-button--secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowAll(true);
                  }}
                  href="/finance"
                >
                  Alle anzeigen
                </a>
              </>
            )}

            {hasFilters && devices.length > 1 && (
              <div>
                {devices.length} von {data.devices.length} SSTs entsprechen
                den Filtern.
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Devices;
