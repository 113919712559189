import React, { useEffect, useState } from "react";
import { useUploadHistory } from "../hooks/useApi";
import { Error, Loading } from "./Helpers";
import Table from "./table/Table";
import { uploadTableHeaders } from "../lib/constants/upload";
import SearchBar from "./SearchBar";
import { reversValue } from "../lib/text";

const UploadHistory = ({ tabFilter }) => {
  const { data, isLoading, error } = useUploadHistory();
  const [entries, setEntries] = useState([]);
  const [slice, setSlice] = useState(50);

  useEffect(() => {
    if (data.history && Array.isArray(data.history) && data.history.map) {
      if (tabFilter) {
        onSearch(tabFilter);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, tabFilter]);

  if (isLoading) {
    return <Loading />;
  }

  const FILTERS = uploadTableHeaders.reduce((acc, header) => {
    acc[header.id] = header.name;
    return acc;
  }, {});
  const onSearch = (_filters = []) => {
    if (!Array.isArray(_filters)) {
      return;
    }
    const filters = _filters.map((filter) => {
      const [key, value] = filter.split(":", 2);
      let originalKey = Object.keys(FILTERS).find(
        (k) => FILTERS[k] === key.trim()
      );
      return { key: originalKey, value: reversValue(value.trim()) };
    });
    const filtered = data.history.filter((item) =>
      filters.every((filter) => {
        if (Array.isArray(item[filter.key])) {
          return item[filter.key].includes(filter.value);
        }
        return String(item[filter.key]) === String(filter.value);
      })
    );
    // console.log("filtered", filtered);
    setEntries(filtered);
  };

  if (error) {
    return <Error error={error} />;
  }
  return (
    <div>
      <SearchBar
        suggestionList={entries}
        mapKeyValues={FILTERS}
        onFilter={onSearch}
        preFiltered={tabFilter}
      />
      <Table data={entries.slice(0,slice)} headers={uploadTableHeaders} />
      {slice < entries.length && (
        <>
          {" "}
          Gefilterete Anzeige {slice} von {entries.length} Zeilen{" "}
          <a
            className="bdr-button bdr-button--secondary"
            onClick={(e) => {
              e.preventDefault();
              setSlice(slice + 100);
            }}
            href="/finance"
          >
            Mehr anzeigen
          </a>
        </>
      )}
    </div>
  );
};
export default UploadHistory;

export const uuidToProcessId = (uuid) =>
  uuid.replace(/_/g, "").replace(/-/g, "");
