import React from "react";
import { EditableField } from "../Field";
import Dropdown from "../Dropdown";

const UserForm = ({ user, setUser, editMode = true }) => {
  const editableField = (displayName, field, value, type = "input") => (
    <EditableField
      displayName={displayName}
      field={field}
      value={value}
      type={type}
      editMode={editMode}
      onChange={(e) => {
        console.log("onChange", field, e.target.value);
        setUser({ ...user, [field]: e.target.value });
      }}
    />
  );

  return (
    <>
      {editableField("Nutzername", "username", user.username)}
      <EditableField
        displayName="Rolle"
        value={user.role}
        type="dropdown"
        editMode={editMode}
        input={
          <Dropdown
            value={user.role}
            options={["ReadOnly", "Admin"]}
            onChange={(e) => {
              setUser({ ...user, role: e.target.value });
            }}
          />
        }
      />
    </>
  );
};

export default UserForm;
