import React from "react";
import { Navigate } from "react-router-dom";
import { getToken } from "../lib/token";

const withAuthentication = (Component) => (props) => {
  const isAuthenticated = getToken();
  if (isAuthenticated) {
    return <Component {...props} />;
  }
  return (
    <Navigate
      to={{
        pathname: "/login",
      }}
    />
  );
};

export default withAuthentication;
