import { germanDay } from "../date";
import { deviceLinkFormatter, stringWithHashTagLinks } from "../links";

export const repairsTableHeaders = [
  { id: "serial_number", name: "SST", customFormatter: deviceLinkFormatter },
  { id: "from", name: "Von", customFormatter: germanDay },
  { id: "to", name: "Bis", customFormatter: germanDay },
  { id: "days", name: "Tage" },
  { id: "state", name: "Status" },
  { id: "reason", name: "Grund", customFormatter: stringWithHashTagLinks },
  { id: "comment", name: "Diagnose", customFormatter: stringWithHashTagLinks },
  { id: "owner", name: "Bearbeiter" },
];

export const repairsTableHeadersNoSerialNumberNoDays =
  repairsTableHeaders.filter(
    (header) => header.id !== "serial_number" && header.id !== "days"
  );
