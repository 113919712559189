import React, { useEffect, useState } from "react";

import { useFinanceFilters } from "../hooks/useApi";
import { Error, Loading } from "../components/Helpers";
import SearchBar from "./SearchBar";
import { financeMappings } from "../lib/constants/orders";
import { reversValue } from "../lib/text";

const FinanceFilters = ({onFilter=()=>{}}) => {
    const { data, isLoading, error } = useFinanceFilters();
    const [filters, setFilters] = useState();

    useEffect(() => {
        if (!data || !Array.isArray(data)) return;
        const _filters = [];
        data.forEach(({name,values}) => {
            if(!values || !Array.isArray(values)) return;
            values.forEach((value) => {
                _filters.push({[name]:value});
            })
        });
        setFilters(_filters);
    }, [data]);
    
    if (isLoading) {
        return <Loading />;
    }
    
    if (error) {
        return <Error error={error} />;
    }
    
    const onSearch = (_filters=[]) => {
        if(!Array.isArray(_filters)){
            return
        }
        const filters = _filters.map((filter) => {
            const [key, value] = filter.split(":", 2);
            let originalKey = Object.keys(financeMappings).find((k) => financeMappings[k] === key.trim())
            return { key:originalKey, value: reversValue( value.trim()) };
        })
        onFilter(filters)
    }

    // return JSON.stringify(filters);

    return <SearchBar 
    suggestionList={filters} 
    onFilter={onSearch}
    placeholder="Auswertung einschränken"
    mapKeyValues={financeMappings}
    />;
    }

export default FinanceFilters;