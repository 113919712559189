import { BdrTypo } from "@style-guide/web-components-react";
import React from "react";
import Table from "../table/Table";
import TableToCSV, { csvName } from "../TableToCSV";
import {
  commonMixedUpsTableHeaders,
  masterFileHeaders,
} from "../../lib/constants/mixedUps";

const MasterFile = ({ master_file }) => {
  const headers = [
    ...masterFileHeaders,
    ...commonMixedUpsTableHeaders,
  ];
  return (
    <div className="content">
      <BdrTypo
        text={`Unstimmigkeiten in der Masterdatei (${master_file.length})`}
        type="headline-2"
      />
      <Table headers={headers} data={master_file} />
      <TableToCSV
        headers={masterFileHeaders}
        data={master_file}
        filename={csvName("masterdatei_unstimmigkeiten")}
        pullRight={true}
      />
    </div>
  );
};

export default MasterFile;
