import React from "react";
import UserForm from "../components/form/forms/UserForm";
import { useNavigate } from "react-router-dom";
import { createUser } from "../hooks/useApi";
import { usersLink } from "../lib/links";
import Button from "../components/form/Button";
import { PlainEditableField } from "../components/form/Field";

const NewUser = () => { 
    const [user, setUser] = React.useState({
        username: "",
        role: "ReadOnly",
    });
    const navigate = useNavigate();
    const reset = () => {
      navigate(usersLink);
    };
    const submit = async () => {
      const {user: {id}} = await createUser(user);
      navigate(usersLink +'/users/'+ id);
    };
    const Buttons = (
      <div className="button-group-right">
        <Button
          onClick={reset}
          text="Abbrechen"
          type="button"
          variant="secondary"
        />
        <Button onClick={submit} text="Speichern" type="submit" />
      </div>
    );
    return (
        <div className="pages device-card editable">
        {Buttons}
            <UserForm user={user} setUser={setUser} />
            <PlainEditableField left="Passwort" right="Passwort wir automatisch generiert" />
        </div>
    );
};

export default NewUser;