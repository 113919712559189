import {
  BdrTable,
  BdrTableBody,
  BdrTableCell,
  BdrTableHeader,
  BdrTableHeaderCell,
  BdrTableHeaderRow,
  BdrTableRow,
} from "@style-guide/web-components-react";
import React from "react";
import {
  anythingIsNumber,
  rateFormater,
  toGermanNumber,
} from "../../lib/numbers";

const HeaderCell = ({
  accessor,
  label,
  onSort,
  unit,
  customFormatter,
  className = "",
}) => {
  let _className = className;

  if (typeof customFormatter === "function") {
    label = customFormatter(label);
  } else if (anythingIsNumber(label)) {
    label = toGermanNumber(label, unit);
    _className = "align-right";
  }

  return (
    <BdrTableHeaderCell
      accessor={accessor}
      slot="table-header-row"
      type="string"
      sortable={typeof onSort === "function"}
    >
      <div slot="table-header-cell" className={_className}>
        {label}
      </div>
    </BdrTableHeaderCell>
  );
};

const TableCell = ({
  children,
  unit,
  customFormatter,
  className = "",
  allData,
}) => {
  let _className = "";
  if (typeof customFormatter === "function") {
    children = customFormatter(children, allData);
    _className = className;
  } else if (anythingIsNumber(children)) {
    children = toGermanNumber(children, unit);
    _className = "align-right";
  }

  return (
    <BdrTableCell slot="table-row">
      <div slot="table-cell" className={_className}>
        {children}
      </div>
    </BdrTableCell>
  );
};
const toSum = (data, key, altText = "") => {
  // Percentages are not summed, but averaged
  if (key.includes("rate")) {
    return rateFormater(
      data.reduce(
        (sum, d) => (anythingIsNumber(d[key]) ? sum + d[key] : sum),
        0
      ) / data.length
    );
  }

  const hasNumber = data.some((d) => anythingIsNumber(d[key]));
  if (!hasNumber) return altText;
  return data.reduce(
    (sum, d) => (anythingIsNumber(d[key]) ? sum + d[key] : sum),
    0
  );
};

const Table = ({
  data = [],
  headers = [],
  onSort,
  _id,
  footers,
  withSums,
  className,
}) => (
  <BdrTable
    _id={_id}
    className={className}
    tableData={data}
    returnData={() => {}}
    handleSorting={onSort}
  >
    <BdrTableHeader slot="table">
      <BdrTableHeaderRow slot="table-header">
        {headers.map(({ id, name }) => (
          <HeaderCell accessor={id} label={name} onSort={onSort} key={id} />
        ))}
      </BdrTableHeaderRow>
    </BdrTableHeader>

    <BdrTableBody slot="table">
      {data.map((data, index) => (
        <BdrTableRow slot="table-row" key={index} className={data.__className}>
          {headers.map(({ id, unit, customFormatter, className }) => (
            <TableCell
              key={id}
              unit={unit}
              customFormatter={customFormatter}
              className={className}
              allData={data}
            >
              {data[id]}
            </TableCell>
          ))}
        </BdrTableRow>
      ))}
    </BdrTableBody>
    {footers?.length > 0 && (
      <BdrTableHeader slot="table">
        <BdrTableHeaderRow slot="table-footer">
          {footers.map(({ id, text, className }) => (
            <HeaderCell
              accessor={id}
              label={text}
              key={id}
              className={className}
            />
          ))}
        </BdrTableHeaderRow>
      </BdrTableHeader>
    )}
    {withSums && (
      <BdrTableHeader slot="table">
        <BdrTableHeaderRow slot="table-footer">
          {headers.map(({ id, unit, customFormatter, className, noSum }, i) => (
            <>
              {!noSum && (
                <HeaderCell
                  accessor={id}
                  label={i === 0 ? "Summe" : toSum(data, id)}
                  key={id}
                  unit={unit}
                  customFormatter={i > 0 ? customFormatter : null}
                  className={className}
                />
              )}
              {noSum && <BdrTableHeaderCell />}
            </>
          ))}
        </BdrTableHeaderRow>
      </BdrTableHeader>
    )}
  </BdrTable>
);

export default Table;
