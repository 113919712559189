import React from "react";
import Button from "./Button";
import { SaveOperation } from "../../lib/token";

export const EditButtonGroup = ({
  editMode,
  setEditMode,
  save,
  reset,
  cancel,
  del,
}) => (
  <SaveOperation>
    <div className="button-group-right">
      {!editMode && (
        <Button
          onClick={() => setEditMode(!editMode)}
          text="Ändern"
          type="button"
          variant="secondary"
        />
      )}
      {editMode && (
        <>
          {reset && (
            <Button
              onClick={reset}
              text="Zurücksetzen"
              type="button"
              variant="secondary"
            />
          )}
          {cancel && (
            <Button
              onClick={cancel}
              text="Abbrechen"
              type="button"
              variant="secondary"
            />
          )}
          {save && <Button onClick={save} text="Speichern" type="submit" />}
          <br />
          <br />

          {del && (
            <Button
              onClick={del}
              text="Löschen"
              type="button"
              variant="tertiary"
            />
          )}
        </>
      )}
    </div>
  </SaveOperation>
);
