import React from "react";
import { MapContainer, TileLayer, GeoJSON, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import geojson from "./DEU.geo.json";
import InLine from "./InLine";
import Card from "./Card";
import { DeviceLink } from "../../lib/links";
import { toGermanNumber } from "../../lib/numbers";
import { LegendColorControl, LegendCountControl } from "./Legend";
import { useMapStats } from "../../hooks/useApi";
import { Error } from "../Helpers";
import { RELATIVE_URL } from "../../hooks/url";

const icon = L.icon({ iconUrl: "/markers-plain.png" });

const position = [51.3657, 10.4515];

const Map = ({ items, findProperty, title }) => {
  const DEFAULT_STYLE = {
    color: "lightgrey",
    weight: 0,
    opacity: 1,
  };

  const maxCount = items.reduce((acc, place) => {
    if (place.count > acc) {
      return place.count;
    }
    return acc;
  }, 0);

  // from red to green (hue 30 to 120)
  const calcColor = (count) => {
    const hue = 30 + (count / maxCount) * 90;
    return `hsl(${hue}, 100%, 50%)`;
  };
  const steps = [0, 0.1, 0.2, 0.3, 0.5, 0.8];

  const colorsLegend = steps.map((value) => ({
    color: calcColor(value * maxCount),
    label: `${Math.round((value * maxCount) / 1000) * 1000} €`,
  }));

  const geoJSONStyle = (feature) => {
    if (!items) return DEFAULT_STYLE;
    const found = findProperty(feature);
    if (found !== undefined) {
      return {
        color: calcColor(found.count),
        weight: 0.1,
        opacity: 1,
        fillOpacity: 0.7,
      };
    } else {
      return DEFAULT_STYLE;
    }
  };

  const onEachFeature = (feature, layer) => {
    if (!items) return;
    const found = findProperty(feature);
    if (found !== undefined) {
      layer.bindPopup(
        found.authority + ": " + toGermanNumber(found.count, "€")
      );
    }
  };

  return (
    <Card title={title}>
      <MapContainer
        center={position}
        zoom={6}
        style={{ height: "680px", width: "540px" }}
      >
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
          attribution="Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ"
        />
        <GeoJSON
          data={geojson}
          style={geoJSONStyle}
          icon={icon}
          onEachFeature={onEachFeature}
        />
        <LegendColorControl ranges={colorsLegend} />
      </MapContainer>
    </Card>
  );
};
export default Map;

/**
 * Places Markers on Map
 * places = [{Lat:...,Lng:...},...]
 *
 * Marker
 * https://github.com/pointhi/leaflet-color-markers
 * BSD-2-Clause license
 *
 */
export const PlacesMap = ({ places }) => {
  const marker = {
    iconUrl: RELATIVE_URL + "marker-icon-2x-green.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowUrl: RELATIVE_URL + "marker-shadow.png",
    shadowSize: [41, 41],
    shadowAnchor: [12, 41],
  };
  const greenIcon = new L.Icon({
    ...marker,
    iconUrl: RELATIVE_URL + "marker-icon-2x-green.png",
  });
  const redIcon = new L.Icon({
    ...marker,
    iconUrl: RELATIVE_URL + "marker-icon-2x-red.png",
  });
  const yellowIcon = new L.Icon({
    ...marker,
    iconUrl: RELATIVE_URL + "marker-icon-2x-yellow.png",
  });

  const placeToIcon = (place) => {
    if (place.device_ids.length > 5) {
      return redIcon;
    }
    if (place.device_ids.length > 2) {
      return yellowIcon;
    }
    return greenIcon;
  };

  return (
    <Card title={`Geräteverteilung in den letzten 12 Monaten`}>
      <MapContainer
        center={position}
        zoom={6}
        style={{ height: "680px", width: "540px" }}
      >
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
          attribution="Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ"
        />
        {places.map((place, i) => (
          <Marker
            position={[place.lat, place.lng]}
            key={`p_${i}`}
            icon={placeToIcon(place)}
          >
            <Popup>
              <div>
                <p>{place.authority}</p>
                {place.device_ids.map((id, i) => (
                  <div key={`d_${i}`}>
                    <DeviceLink device_id={id} />
                  </div>
                ))}
              </div>
            </Popup>
          </Marker>
        ))}
        <LegendCountControl
          ranges={[
            { icon: greenIcon, label: "1-2 SSTs" },
            { icon: yellowIcon, label: "3-5 SSTs" },
            { icon: redIcon, label: ">5 SSTs" },
          ]}
        />
      </MapContainer>
    </Card>
  );
};

export const SalesVolumeMap = ({ sales_volume }) => (
  <Map
    title={`Umsatzverteilung in den letzten 12 Monaten`}
    items={sales_volume}
    findProperty={(feature) =>
      sales_volume.find((place) => place.geo_id === feature.properties.DEBKG_ID)
    }
  />
);

export const AllMaps = () => {
  const { data, error } = useMapStats();

  if (error) {
    return <Error error={error} />;
  }
  return (
    <InLine>
      {data && data.places && <PlacesMap {...data} />}
      {data && data.sales_volume && <SalesVolumeMap {...data} />}
    </InLine>
  );
};
