import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ComposedChart,
  Area,
} from "recharts";
import Card from "./Card";
import { Link } from "react-router-dom";
import { financesLink } from "../../lib/links";
import { colors, less } from "../../lib/colors";

export const MyBarChartInCard = ({
  data,
  title,
  width,
  style,
  children,
  keyX = "name",
  keyY = "Anzahl",
  keyYUnit,
}) => {
  width = width || 1000;
  return (
    <Card title={title} style={style}>
      <MyBarChart
        data={data}
        width={width}
        keyX={keyX}
        keyY={keyY}
        keyYUnit={keyYUnit}
      />
      {children}
    </Card>
  );
};

export const MyBarChart = ({
  data,
  width,
  keyX = "name",
  keyY = "Anzahl",
  keyYUnit,
}) => {
  width = width || 1000;
  return (
    <BarChart
      width={width}
      height={250}
      data={data}
      style={{ marginLeft: "-20px" }}
    >
      <XAxis dataKey={keyX} />
      <YAxis dataKey={keyY} unit={keyYUnit} />
      <Tooltip />

      <Bar dataKey={keyY} fill={less} />
    </BarChart>
  );
};

export const OrdersByMonth = ({ data }) =>
  MonthChart({
    data,
    title: "Transaktionen der letzten 12 Monate",
    link: financesLink,
  });

export const DevicesByMonth = ({ data }) =>
  MonthChart({
    data,
    title: "Aktive SSTs in den letzten 12 Monaten",
  });

export const MonthChart = ({ data, title, link }) => {
  const _data = data.map((item) => ({
    name: item.month.substring(0, 7),
    Anzahl: item.count,
  }));
  return (
    <MyBarChartInCard data={_data} title={title} dataKey="orders">
      {link && (
        <Link className="more-link" to={link}>
          mehr...
        </Link>
      )}
    </MyBarChartInCard>
  );
};

export const RepairsByMonth = ({ repairs_by_month }) => {
  const _data = repairs_by_month.map((item) => ({
    name: item.month,
    Anzahl: item.count,
  }));
  return (
    <MyBarChartInCard
      data={_data}
      title="Reparaturen nach Monat"
      dataKey="repairs"
      width={600}
    />
  );
};

export const StackedBarChart = ({
  data,
  stacks = [],
  width,
  primaryDataKey,
}) => {
  width = width || 1000;
  primaryDataKey = primaryDataKey || "name";
  return (
    <BarChart
      width={width}
      height={250}
      data={data}
      colors={["#8884d8", "#82ca9d"]}
      style={{ marginLeft: "-20px" }}
    >
      <XAxis dataKey={primaryDataKey} />
      <YAxis />
      <Tooltip />
      <Legend />
      {stacks.map((stack, index) => (
        <Bar
          dataKey={stack}
          stackId="a"
          fill={index % 2 === 0 ? colors[index] : colors[colors.length - index]}
          key={index}
        />
      ))}
    </BarChart>
  );
};

export const BarAreaChart = ({
  data,
  barKey,
  areaKey,
  width,
  height,
  primaryDataKey,
  headers,
}) => {
  width = width || 1000;
  height = height || 250;
  primaryDataKey = primaryDataKey || "name";
  const _data = data.map((d) => {
    const item = {};
    item[primaryDataKey] = d[primaryDataKey];
    headers.forEach((header) => {
      item[header.name] = d[header.id];
    });
    return item;
  });
  const _barKey = headers.find((header) => header.id === barKey).name;
  const _areaKey = headers.find((header) => header.id === areaKey).name;
  return (
    <ComposedChart width={width} height={height} data={_data}>
      <YAxis mirror={true} unit="€" />
      <XAxis dataKey={primaryDataKey} />
      <Tooltip />
      <Legend />
      <Area type="monotone" dataKey={_areaKey} fill="#82ca9d" />
      <Bar dataKey={_barKey} fill="#8884d8" />
    </ComposedChart>
  );
};

export const FinanceChart = ({
  data,
  barKey,
  areaKey1,
  areaKey2,
  width,
  height,
  headers,
}) => {
  const headerMap = headers.reduce(
    (acc, header) => ({ ...acc, [header.id]: header }),
    {}
  );
  const barKeyHeader = headerMap[barKey];
  const areaKey1Header = headerMap[areaKey1];
  const areaKey2Header = headerMap[areaKey2];
  const _data = data.map((item) => ({
    name: item.month,
    [barKeyHeader.name]: item[barKey],
    [areaKey1Header.name]: item[areaKey1],
    [areaKey2Header.name]: item[areaKey2],
  }));
  width = width || 1000;
  height = height || 250;

  const CustomTooltip = ({ active, payload, label }) => {
    console.log(active, payload, label);
    if (active && payload && payload.length) {
      return (
        <div
          className="recharts-default-tooltip"
          style={{
        margin: "0px",
        padding: "10px",
        backgroundColor: "white",
        border: "1px solid rgb(153, 153, 153)",
        whiteSpace: "nowrap",
          }}
        >
          <h1>{label}</h1>
          <p className="label" style={{color: "#4d9978"}}>{`${payload[0].name} : ${
        barKeyHeader.customFormatter
          ? barKeyHeader.customFormatter(payload[0].value)
          : payload[0].value
          }`}</p>
          <p className="label" style={{color:"#8c8f8f"}}>{`${payload[1].name} : ${
        areaKey1Header.customFormatter
          ? areaKey1Header.customFormatter(payload[1].value)
          : payload[1].value
          }`}</p>
          <p className="label" style={{color:"#5952a6"}}>{`${payload[2].name} : ${
        areaKey2Header.customFormatter
          ? areaKey2Header.customFormatter(payload[2].value)
          : payload[2].value
          }`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <ComposedChart width={width} height={height} data={_data}>
      <YAxis yAxisId="left" mirror={true} unit="€" />
      <YAxis yAxisId="right" orientation="right" />
      <XAxis dataKey="name" />
      <Tooltip content={<CustomTooltip />} />
      <Legend />
      <Bar dataKey={barKeyHeader.name} fill="#82ca9d" yAxisId="right" />
      <Area
        type="monotone"
        dataKey={areaKey1Header.name}
        fill="#D5DBDB"
        yAxisId="left"
      />
      <Area
        type="monotone"
        dataKey={areaKey2Header.name}
        fill="#8884d8"
        yAxisId="left"
      />
    </ComposedChart>
  );
};
