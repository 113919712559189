import {
  BdrInputPassword,
  BdrInputText,
  BdrButton,
} from "@style-guide/web-components-react";
import React, { useRef, useState } from "react";
import { Formik, Form } from "formik";
import { login } from "../hooks/useApi";
import { BodyText } from "../components/screen/Text";
import { setToken } from "../lib/token";
import { handleErrorResponse } from "../lib/errors";
import { ErrorMessageBox } from "../components/Helpers";

const Login = () => {
  const usernameRef = useRef();
  const passwordRef = useRef();
  const [validationResult, setValidationResult] = useState();
  const [errorText, setErrorText] = useState(false);
  const formikProps = {
    initialValues: { "input-username": "", "input-password": "" },
    validate: (values) => {},
    onSubmit: (values) => {
      const { "input-username": username, "input-password": password } = values;
      login(username, password)
        .then(({ token }) => {
          if (!token) throw new Error("Falscher Benutzername oder Passwort");
          setToken(token);
          window.location.href = "/ui";
        })
        .catch((e) => {
          console.log("e", e);

          let errorMessage = handleErrorResponse(e.message);
          if(errorMessage.includes(": {")) {
            const reasonText = errorMessage.split(": ",2).pop()
            const reason = JSON.parse(reasonText);
            const { tryAgainTime } = reason;
            errorMessage = `Zu viele Fehlversuche. Versuchen Sie es erneut um ${new Date(tryAgainTime).toLocaleTimeString()} Uhr.`;
          }
          setErrorText(errorMessage);
          setValidationResult({
            helpText: e.message.error,
            helpClass: "error",
          });
        });
    },
  };
  return (
    <div className="pages">
      {<ErrorMessageBox error={errorText} />}
      <Formik {...formikProps}>
        {(formik) => (
          <Form
            style={{
              display: "inline-flex",
              flexFlow: "column",
            }}
          >
            <BodyText>Username</BodyText>
            <BdrInputText
              name="input-username"
              _id="username"
              ref={usernameRef}
              inputChange={formik.handleChange}
            />
            <br />
            <BodyText>Passwort</BodyText>
            <BdrInputPassword
              inputChange={formik.handleChange}
              ref={passwordRef}
              _id="password"
              validationResult={validationResult}
            />
            <BdrButton text="Login" type="submit" _id="login-button" />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
