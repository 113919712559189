import { BdrHeader } from "@style-guide/web-components-react";
import React from "react";

function logProps(Comp) {
  class LogProps extends React.Component {
    render() {
      return <Comp {...this.props} />;
    }
  }

  return LogProps;
}

export const Header = logProps(BdrHeader);
