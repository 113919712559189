import { germanDay, isISODate } from "./date";

export const extractTags = (text) => {
  const tags = text.match(/#[a-zA-Z0-9äöüÄÖÜß\-_]+/gi);
  return tags || [];
};

export const formatValue = (value) => {
  if (typeof value === "boolean") {
    return value ? "Ja" : "Nein";
  }
  if(isISODate(value)) {
    return germanDay(value);
  }
  return value;
};

export const reversValue = (value) => {
  if (typeof value === "string") {
    if (value === "Ja") {
      return true;
    }
    if (value === "Nein") {
      return false;
    }
  }
  return value;
}

export const displayArray = (array) => {
  if(!Array.isArray(array)) {
    return array;
  }
  if (array.length === 0) {
    return "-";
  }
  return array.join(", ");
};

export const nl2br = (str) => {
  if (typeof str === "string") {
    return str.split("\n").map((item, key) => {
      return (
        <span key={key}>
          {item}
          <br />
        </span>
      );
    });
  }
  return str;
}


export const noZero = (value) => {
  if (value === "0") {
    return "";
  }
  return value;
}