import React from "react";
import { BdrTypo } from "@style-guide/web-components-react";

import Dropdown from "../Dropdown";
import { EditableField } from "../Field";
import { conflictStates } from "../../../lib/constants/mixedUps";

const ConflictForm = ({ conflict, editMode, setConflict }) => {
  let { comment, state, assigned_to } = conflict;

  const editableField = (displayName, field, value, type = "input") => (
    <EditableField
      displayName={displayName}
      field={field}
      value={value}
      type={type}
      editMode={editMode}
      onChange={(e) => {
        console.log("onChange", field, e.target.value);
        setConflict({ ...conflict, [field]: e.target.value });
      }}
    />
  );

  return (
    <>
      <div className="table-row">
        <div className="table-cell pr-4">
          <BdrTypo type="body-medium">Status</BdrTypo>
        </div>
        <div className="table-cell">
          {editMode && (
            <Dropdown
              value={state}
              options={conflictStates}
              disabledOptions={["Automatisch gelöst"]}
              onChange={(e) => {
                setConflict({ ...conflict, state: e.target.value });
              }}
            />
          )}
          {!editMode && <>{state}</>}
        </div>
      </div>
      {editableField("Zugewiesen an", "assigned_to", assigned_to)}
      {editableField("Kommentar", "comment", comment, "textarea")}
    </>
  );
};

export default ConflictForm;
