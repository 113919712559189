import React, { useState } from "react";
import { EditableField } from "./form/Field";
import { Link } from "react-router-dom";
import Button from "./form/Button";
import { resetMyPassword } from "../hooks/useApi";
import { handleErrorResponse } from "../lib/errors";
import { ErrorMessageBox, SuccessMessageBox } from "./Helpers";
import { setToken } from "../lib/token";

const User = () => {
  const [user, setUser] = useState({
    password: "",
    old_password: "",
  });
  const [errorText, setErrorText] = useState(false);
  const [successText, setSuccessText] = useState(false);
  const [reloadForm, setReloadForm] = useState(1);

  const editableField = (displayName, field, value, type = "input") => (
    <EditableField
      displayName={displayName}
      field={field}
      value={value}
      type={type}
      key={`editableField-${field}-${reloadForm}`}
      editMode={true}
      onChange={(e) => {
        setUser({ ...user, [field]: e.target.value });
      }}
    />
  );

  const save = async () => {
    setSuccessText(false);
    setErrorText(false);
    if (user.password && user.old_password) {
      try {

        const result = await resetMyPassword(user.password, user.old_password);
        if (result.error) {
          throw new Error(JSON.stringify(result));
        } else {
          setSuccessText("Passwort erfolgreich geändert");
          setUser({
            password: "",
            old_password: "",
          });
          if(result.token){
            setToken(result.token);
          }
          setReloadForm(reloadForm + 1);
        }
      } catch (error) {
        console.log("error updateUser", error);
        const errorMessage = handleErrorResponse(error.message);
        setErrorText(errorMessage);
      } 
    } else {
      setErrorText("Bitte füllen Sie alle Felder aus")
    }
  };

  return (
    <>
      {<SuccessMessageBox success={successText} />}
      {<ErrorMessageBox error={errorText} />}
      <div className="button-group-right">
        <Link to="/settings/" onClick={save}>
          <Button text="Speichern" type="button" />
        </Link>
        <br />
      </div>
      {editableField(
        "Aktuelles Passwort",
        "old_password",
        user.old_password,
        "password"
      )}
      {editableField("Neues Passwort", "password", user.password, "password")}
    </>
  );
};

export default User;
