import React, { useEffect, useState } from "react";

import "./ExchangededDevices.css";
import { BdrMessage } from "@style-guide/web-components-react";
import {
  createExchange,
  deleteExchange,
  directExchanges,
} from "../hooks/useApi";
import { Error, Loading } from "./Helpers";
import { DeviceLink } from "../lib/links";
import Button from "./form/Button";
import { BodyText, H3 } from "./screen/Text";

/**
 * loadedData = [{"2023-01":30,"2023-02":12,"2023-03":20,"2023-04":29,"2023-05":0,"2023-06":25,"2023-07":69,"2023-08":69,"2023-09":16,"2023-10":0,"2023-11":0,"2023-12":0,"location":"Herrenberg","device_id":"0027092203","minimum_sales":480,"process_engeneer":"0","sum":750},{"2023-01":0,"2023-02":0,"2023-03":0,"2023-04":0,"2023-05":0,"2023-06":0,"2023-07":0,"2023-08":0,"2023-09":39,"2023-10":48,"2023-11":56,"2023-12":0,"location":"Herrenberg","device_id":"0029112201","minimum_sales":480,"process_engeneer":"0","sum":623}]
 * find the device_id with all zeros starting in one month and another device_id with all zeros bevor this exact same month
 */
const ExchangededDevices = ({ matches, months, location, year }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loadedExchanges, setLoadedExchanges] = useState([]);
  const [exchanges, setExchanges] = useState(matches);

  useEffect(() => {
    if (data.exchanges && Array.isArray(data.exchanges)) {
      const current = data.exchanges.filter(
        (known) => months.includes(known.month) && location === known.location
      );
      setLoadedExchanges(current);
      setExchanges(
        matches.filter(
          ({ device_id }) =>
            !current.find(({ from_device_id }) => from_device_id === device_id)
        )
      );
    }
  }, [data, matches, location, months]);
  const loadData = () => {
    directExchanges(year)
      .then((moreData) => {
        setData({ exchanges: moreData.exchanges });
        setIsLoading(false);
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
      });
  };
  if (data.length === 0) {
    loadData();
  }
  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <div className="exchanged-devices">
      {loadedExchanges.map((exchange, index) => {
        return (
          <MessageBox
            state="success"
            key={index}
            title={`Gerätetausch ${exchange.from_device_id} → ${exchange.to_device_id}`}
            text={
              <>
                <DeviceLink device_id={exchange.from_device_id} /> wurde durch{" "}
                <DeviceLink device_id={exchange.to_device_id} /> in{" "}
                {exchange.month} ersetzt!
              </>
            }
            buttons={
              <Button
                size="small"
                text="Löschen"
                type="button"
                variant="tertiary"
                onClick={async () => {
                  const result = window.confirm("Gerätetausch löschen?");
                  if (!result) {
                    return;
                  }
                  try {
                    await deleteExchange(exchange);
                  } catch (e) {
                    setError(e);
                  }
                  loadData();
                }}
              />
            }
          />
        );
      })}
      {exchanges.map((match, index) => {
        return (
          <MessageBox
            state="warning"
            key={index}
            title={`${match.device_id} → ${match.device_id2}`}
            text={`Wurde ${match.device_id} durch ${match.device_id2} in ${match.lastMonth} ersetzt?`}
            buttons={
              <Button
                size="small"
                text="Ja"
                type="button"
                variant="secondary"
                onClick={async () => {
                  await createExchange({
                    from_device_id: match.device_id,
                    to_device_id: match.device_id2,
                    month: match.lastMonth,
                    location,
                  });
                  loadData();
                }}
              />
            }
          />
        );
      })}
    </div>
  );
};

export const MessageBox = ({ state, title, text, buttons }) => (
  <BdrMessage status={state}>
    <H3 slot="message-title" type="headline-3">
      {title}
    </H3>
    <div slot="message-content">
      <BodyText>{text}</BodyText>
      <div className="button-group">{buttons}</div>
    </div>
  </BdrMessage>
);

export const MessageBoxSlim = ({ state, text }) => (
  <div style={{ marginTop: "25px" }}>
    <BdrMessage status={state}>
      <div slot="message-title" type="headline-3">
        <BodyText>{text}</BodyText>
      </div>
    </BdrMessage>
  </div>
);

export const findMatches = (loadedData, months) => {
  loadedData = loadedData.filter((item) => item.minimum_sales > 0);

  const lastFilledMonth = loadedData.reduce((acc, item) => {
    for (let i = months.length - 1; i >= 0; i--) {
      const month = months[i];
      if (item[month] && item[month] > 0) {
        acc[item.device_id] = month;
        return acc;
      }
    }
    return acc;
  }, {});
  const firstFilledMonth = loadedData.reduce((acc, item) => {
    for (let i = 0; i < months.length; i++) {
      const month = months[i];
      if (item[month] && item[month] > 0) {
        acc[item.device_id] = month;
        return acc;
      }
    }
    return acc;
  }, {});
  const matches = [];
  Object.keys(lastFilledMonth).forEach((device_id) => {
    const lastMonth = lastFilledMonth[device_id];
    Object.keys(firstFilledMonth).forEach((device_id2) => {
      const firstMonth = firstFilledMonth[device_id2];
      const beforeFirstMonth = months[months.indexOf(firstMonth) - 1];
      if (
        device_id !== device_id2 &&
        (firstMonth === lastMonth || lastMonth === beforeFirstMonth)
      ) {
        matches.push({ device_id, device_id2, lastMonth, firstMonth });
      }
    });
  });
  return matches
    .filter(({ lastMonth }) => lastMonth !== months[0])
    .filter(({ firstMonth }) => firstMonth !== months[months.length - 1]);
};

export default ExchangededDevices;
