export const timeRangeFormatter = (from, to) => {
  const fromTime = new Date(from);
  const toTime = new Date(to);
  const range =
    fromTime.toLocaleString("de-DE") + " - " + toTime.toLocaleString("de-DE");
  return range;
};

export const dateDiffInDays = (a, b) => {
  const from = new Date(a);
  const to = new Date(b);
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  return Math.floor((to - from) / _MS_PER_DAY);
};

export const germanDay = (day) =>
  day
    ? new Date(day).toLocaleString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
    : null;
export const germanDayTime = (day) =>
  day
    ? new Date(day).toLocaleString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      })
    : null;

export const germanMonth = (day) => {
  if (!day) return null;
  day = day.split("-");
  day = day[0] + "-" + day[1] + "-01";
  return new Date(day).toLocaleString("de-DE", {
    year: "numeric",
    month: "short",
  });
};

export const dateIsAfter = (a, b) => {
  const from = new Date(a);
  const to = new Date(b);
  return from >= to;
};

export const isISODate = (value) => {
  if (!value) return false;
  if(typeof value !== "string"){
    return false;
  }
  if (
    (!value.includes("-") || !value.includes("T") || !value.includes(":"))
  ) {
    return false;
  }
  const date = new Date(value);
  return !isNaN(date.getTime());
};
