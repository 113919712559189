import ERROR_CODES from "./constants/errorcodes";

export const handleErrorResponse = (message) => {
  try {
    if (message.includes("errorCode")) {
      const responseBody = JSON.parse(message);

      return errorToMessage(responseBody);
    }
  } catch (e) {}

  throw new Error(message);
};

const errorToMessage = (error) => {
  if (ERROR_CODES[error.errorCode]) {
    if(error.errorReason) {
      return `${ERROR_CODES[error.errorCode]}: ${JSON.stringify(error.errorReason)}`;
    }
    return ERROR_CODES[error.errorCode];
  }
  return `Ein unbekannter Fehler ist aufgetreten: ${error.errorCode}`;
};
