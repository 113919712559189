import React, { useEffect, useState } from "react";
import { useUsers } from "../hooks/useApi";
import { Error, Loading } from "./Helpers";
import { Link } from "react-router-dom";
import Button from "./form/Button";
import Table from "./table/Table";
import { usersHeaders } from "../lib/constants/users.js";

const Users = () => {
  const { data, error, isLoading } = useUsers();
  const [users, setUsers] = useState(data?.users);

  const handleUsers = (users) =>
    users.map((user) => {
      user.link = (
        <>
          <Link to={`/settings/users/${user.id}`}>
            {user.username}
          </Link>
        </>
      );
      return user;
    });

  useEffect(() => {
    if (data?.users) {
      setUsers(handleUsers(data.users));
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }
  return (
    <>
      <div className="button-group-right">
        <Link to="/settings/users/new">
          <Button text="Neue Nutzer" type="button" variant="secondary" />
        </Link>
        <br />
      </div>
      <Table headers={usersHeaders} data={users} />
    </>
  );
};

export default Users;
