import React, { useEffect, useState } from "react";
import { useExchangeRateStats } from "../../hooks/useApi";
import { Error, Loading } from "../Helpers";
import { MyBarChart } from "./BarCharts";
import Card from "./Card";
import { rateFormater } from "../../lib/numbers";
import { BdrTypo } from "@style-guide/web-components-react";
import Table from "../table/Table";
import { exchangeRateHeaders } from "../../lib/constants/stats";
import InfoButton from "../screen/Info";

const ExchangeRate = () => {
  const { data, isLoading, error } = useExchangeRateStats();
  const [byYear, setByYear] = useState([]);
  const [byHardware, setByHardware] = useState([]);
  const [byReason, setByReason] = useState([]);
  const [totals, setTotals] = useState([]);
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    const REASONS = [
      "#Gewährleistung",
      "#Kunde",
      "#Transportschaden",
      "#NichtReproduzierbar",
      "#System",
    ];

    if (data?.devices && data?.repairs) {
      const years = [...new Set(data.devices.map(({ year }) => year))].sort();
      const _byYear = years.map((year) => {
        const _devices = data.devices.filter(({ year: y }) => y === year);
        const devices = _devices.reduce((acc, { count }) => acc + count, 0);
        const _repairs = data.repairs.filter(({ year: y }) => y === year);
        const repairs = _repairs.reduce((acc, { count }) => acc + count, 0);
        const rate = Math.round((repairs / devices) * 100 * 100) / 100;
        return { year, devices, repairs, Austauschrate: rate };
      });
      setByYear(_byYear);
      const hardwares = [
        ...new Set(
          data.devices.map(({ hardware_version }) => hardware_version)
        ),
      ]
        .sort()
        .reverse();
      const _byHardware = hardwares.map((hardware) => {
        const _devices = data.devices.filter(
          ({ hardware_version: h }) => h === hardware
        );
        const devices = _devices.reduce((acc, { count }) => acc + count, 0);
        const _repairs = data.repairs.filter(
          ({ hardware_version: h }) => h === hardware
        );
        const repairs = _repairs.reduce((acc, { count }) => acc + count, 0);
        const rate = Math.round((repairs / devices) * 100 * 100) / 100;
        return { hardware, devices, repairs, Austauschrate: rate };
      });
      setByHardware(_byHardware);

      const _byReason = data.repairsByReason
        .filter(({ name }) => REASONS.includes(name))
        .map(({ name, count }) => {
          const rate =
            Math.round((count / data.devices_delivered) * 100 * 100) / 100;
          return {
            reason: name,
            devices: data.devices_delivered,
            repairs: count,
            Austauschrate: rate,
          };
        });

      setByReason(_byReason);

      setTotals({
        active:
          data.repairsTotal.find(({ name }) => name === "active").count /
          data.devicesTotal.find(({ name }) => name === "active").count,
        all:
          data.repairsTotal.reduce((acc, { count }) => acc + count, 0) /
          data.devicesTotal.reduce((acc, { count }) => acc + count, 0),
      });
    }
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  const Header = (
    <>
      <BdrTypo id="defaultTypo" type="headline-4">
        Austauschraten
      </BdrTypo>
      <BdrTypo id="defaultTypo" type="headline-5">
        über alle Jahre der jetzt aktiven Geräte: {rateFormater(totals.active)}
      </BdrTypo>
      <BdrTypo id="defaultTypo" type="headline-5">
        über alle Jahre über alle Geräte: {rateFormater(totals.all)}
      </BdrTypo>
      <div className="align-right">
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setShowTable(!showTable);
          }}
        >
          {!showTable ? "Berechnungsgrundlage" : "Auswertung"} anzeigen
        </a>
      </div>
    </>
  );
  return (
    <Card header={Header}>
      {!showTable && (
        <>
          <MyBarChart
            data={byYear}
            keyX="year"
            keyY="Austauschrate"
            keyYUnit="%"
          />
          <MyBarChart
            data={byHardware}
            keyX="hardware"
            keyY="Austauschrate"
            keyYUnit="%"
          />
          <MyBarChart
            data={byReason}
            keyX="reason"
            keyY="Austauschrate"
            keyYUnit="%"
          />
        </>
      )}
      {showTable && (
        <>
          <Table
            data={byYear}
            headers={[
              {
                id: "year",
                name: "Jahr",
                customFormatter: (value) => value,
              },
              {
                id: "devices",
                name: <>
                Geräte
                <InfoButton>Anzahl der Geräte, die in diesem Jahr aktiv waren.<br/>Beinhaltet auch Geräte, die nur einige Monate aktiv waren.</InfoButton>
                </>,
              },
              ...exchangeRateHeaders.filter(({ id }) => id!== "devices"),
            ]}
          />
          <br />
          <Table
            data={byHardware}
            headers={[
              {
                id: "hardware",
                name: "Hardware",
                customFormatter: (value) => value,
              },
              ...exchangeRateHeaders,
            ]}
          />
          <br />
          <Table
            data={byReason}
            headers={[
              {
                id: "reason",
                name: "Grund",
                customFormatter: (value) => value,
              },
              ...exchangeRateHeaders,
            ]}
          />
        </>
      )}
    </Card>
  );
};

export default ExchangeRate;
