import React, { useState } from "react";
import INFO from "@style-guide/assets/pictograms/info.svg";
import { BdrPictogram } from "@style-guide/web-components-react";
import "./Info.css";
import { BodyText } from "./Text";

const InfoButton = ({ children }) => {
  const [infoPict, setInfoPict] = useState();
  const [showText, setShowText] = useState(false);

  fetch(INFO)
    .then((r) => r.text())
    .then((text) => {
      setInfoPict(text);
    });

  return (
    <div
      onMouseEnter={() => setShowText(true)}
      onMouseLeave={() => setShowText(false)}
      className="info-button"
    >
      <BdrPictogram pictogram={infoPict} className="info-icon" />
      {showText && (
        <div className="info-text">
          <BodyText>{children}</BodyText>
        </div>
      )}
    </div>
  );
};

export default InfoButton;
