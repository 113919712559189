import { BdrTypo } from "@style-guide/web-components-react";
import React from "react";
import Table from "../table/Table";
import TableToCSV, { csvName } from "../TableToCSV";
import {
  commonMixedUpsTableHeaders,
  mixedSAPOnlyTableHeaders,
} from "../../lib/constants/mixedUps";

const DevicesSAPOnly = ({ devices_unknown_to_l_and_d, onChange }) => {
  const headers = [
    ...mixedSAPOnlyTableHeaders,
    ...commonMixedUpsTableHeaders,
  ];
  return (
    <div className="content">
      <BdrTypo
        text={`SSTs in Abrechnungsdateien, aber nicht im L&D-Report (${devices_unknown_to_l_and_d.length})`}
        type="headline-2"
      />
      <Table headers={headers} data={devices_unknown_to_l_and_d} />
      <TableToCSV
        headers={mixedSAPOnlyTableHeaders}
        data={devices_unknown_to_l_and_d}
        filename={csvName("sst_nur_in_sap")}
        pullRight={true}
      />
    </div>
  );
};

export default DevicesSAPOnly;
