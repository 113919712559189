import InfoButton from "../../components/screen/Info";

export const statsByVersionHeaders = [
  {
    name: "Version",
    id: "name",
    customFormatter: (value) => <b>{value}</b>,
    className: "align-right",
  },
  {
    name: "Bereit zur Auslieferung - neu",
    id: "devices_ready_for_delivery",
  },
  {
    name: "Bereit zur Auslieferung - alt",
    id: "devices_ready_for_delivery_old",
  },
  {
    name: "In Betrieb",
    id: "devices_delivered",
    customFormatter: (value) => <b>{value}</b>,
    className: "align-right",
  },
  {
    name: "In Reparatur",
    id: "devices_in_repair",
  },
  {
    name: "Verschrottet",
    id: "devices_sorted_out",
  },
];

export const statsByDeviceTypeHeaders = statsByVersionHeaders
  .filter(({ id }) => id !== "name")
  .map(({ id }) => id);

export const exchangeRateHeaders = [
  {
    id: "devices",
    name: "Geräte",
  },
  {
    id: "repairs",
    name: "Reparaturen",
  },
  {
    id: "Austauschrate",
    name: "Austauschrate",
    customFormatter: (value, allData) => (
      <>
        {value}%
        <InfoButton>Anzahl Reparaturen / Anzahl Geräte<br/>
        {allData["repairs"]} / {allData["devices"]} = {value/100}
        </InfoButton>
      </>
    ),
    className: "align-right",
  },
];
