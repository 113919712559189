export const sortByKey = (array, key, sortDirection = "ASC") => {
  if (!array || !Array.isArray(array) || array.length === 0) return array;
  return [...array].sort((a, b) => {
    if(a[key]===undefined || a[key]===null || a[key]==='') return 1;
    if(b[key]===undefined || b[key]===null || b[key]==='') return -1;
    if (a[key] < b[key]) {
      return sortDirection === "ASC" ? -1 : 1;
    }
    if (a[key] > b[key]) {
      return sortDirection === "ASC" ? 1 : -1;
    }
    return 0;
  });
};
