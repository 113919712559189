import React from "react";
import { Link } from "react-router-dom";
import { SaveOperation } from "./token";

export const deviceLink = (device_id) => `/devices/${device_id}`;
export const DeviceLink = ({ device_id }) => (
  <Link to={deviceLink(device_id)}>{device_id}</Link>
);
export const deviceLinkFormatter = (value) => <DeviceLink device_id={value} />;

export const devicesFilterLink = (filters) => `/devices#${filters}`;
export const DevicesFilterLink = (name,filters) => (
  <Link to={devicesFilterLink(filters)}>{name}</Link>
);

export const contractsFilterLink = (filters) => `/contracts#${filters}`;
export const ContractsFilterLink = (name,filters) => (
  <Link to={contractsFilterLink(filters)}>{name}</Link>
);

export const conflictLink = (id) => `/mixedup/${id}`;
export const ConflictLink = ({ id, state }) => <SaveOperation altText={state}><Link to={conflictLink(id)}>{state}</Link></SaveOperation>;

export const financesLink = `/finance/`;

export const stringWithHashTagLinks = (string) => {
  if (typeof string !== "string") {
    return string;
  }
  if (!string.includes("#")) {
    return string;
  }
  const words = string.split(" ");
  const wordsWithLinks = [];
  words.forEach((word, index) => {
    if (word.startsWith("#")) {
      let tag = word.substring(1);
      //remove trailing punctuation
      if (tag.endsWith(".") || tag.endsWith(",")) {
        tag = tag.substring(0, tag.length - 1);
      }
      wordsWithLinks.push(
        <TagLink key={`${word}_${index}`} tag={tag} text={word} />
      );
    } else {
      wordsWithLinks.push(word);
    }
    wordsWithLinks.push(" ");
  });
  return <>{wordsWithLinks}</>;
};

export const TagLink = ({ tag, text }) => (
  <>
    <Link to={`/repairs#${tag}`}>{text}</Link>{" "}
  </>
);


export const contractLink = (id) => `/contracts/${id}`;
export const ContractLink = ({customer, id }) => (
  <Link to={contractLink(id)}>{customer}</Link>
);

export const contractLinkFormatter = (value) => <ContractLink id={value} />;


export const ruleFilterLink = (rule) => `/devices#${rule.id}`;
export const settingsLink = "/settings";
export const usersLink = settingsLink
export const rulesLink = settingsLink
export const deleteRuleLink = (rule) => `${rulesLink}/rules/${rule.id}`;
export const editSaleLink = (sale) => `/sales/${sale}`;

export const jiraLink = (jira_key) => `https://partner.bdr.de/jira/browse/${jira_key}`;
export const jiraLinkFormatter = (value) => <a href={jiraLink(value)}>{value}</a>;

export const salesLinkFormatter = (id,number) => <Link to={editSaleLink(id)}>{number}</Link>;
export const salesLink = "/finance#sonstige";