export const primary = "#030045";
export const secondary = "#296EF5";
export const colors = generateColors(primary, secondary, 20);
export const less = "#03004578";

export function generateColors(startColor, endColor, count) {
  // convert start and end colors to RGB
  var startRGB = hexToRGB(startColor);
  var endRGB = hexToRGB(endColor);

  // calculate step size for each color component
  var stepR = (endRGB.r - startRGB.r) / (count - 1);
  var stepG = (endRGB.g - startRGB.g) / (count - 1);
  var stepB = (endRGB.b - startRGB.b) / (count - 1);

  // generate colors
  var colors = [];
  for (var i = 0; i < count; i++) {
    var r = Math.round(startRGB.r + stepR * i);
    var g = Math.round(startRGB.g + stepG * i);
    var b = Math.round(startRGB.b + stepB * i);
    var color = rgbToHex(r, g, b);
    colors.push(color);
  }

  return colors;
}

export function hexToRGB(hex) {
  var r = parseInt(hex.substring(1, 3), 16);
  var g = parseInt(hex.substring(3, 5), 16);
  var b = parseInt(hex.substring(5, 7), 16);
  return { r: r, g: g, b: b };
}

export function rgbToHex(r, g, b) {
  var hexR = r.toString(16).padStart(2, "0");
  var hexG = g.toString(16).padStart(2, "0");
  var hexB = b.toString(16).padStart(2, "0");
  return "#" + hexR + hexG + hexB;
}
