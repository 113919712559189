import React, { useState } from "react";
import DeviceOrders from "./DeviceOrders";

const DeviceOrdersWrapper = ({ deviceId }) => {
  const [showOrders, setShowOrders] = useState(false);

  return (
    <>
      <a
        href="/devices"
        onClick={(e) => {
          e.preventDefault();
          setShowOrders(!showOrders);
        }}
      >
        Details zu Transaktionen {showOrders ? "verbergen" : "anzeigen"}
      </a>
      {showOrders && <DeviceOrders deviceId={deviceId} />}
    </>
  );
};

export default DeviceOrdersWrapper;
