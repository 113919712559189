import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";

import { directFinance, useFinance } from "../hooks/useApi";
import { Error, Loading, NoData } from "./Helpers";
import {
  financeTableHeaders,
  financeTableHeadersShort,
  financeTableHeadersYearShort,
  financeTableHeadersYear,
} from "../lib/constants/orders";

import { BarAreaChart, FinanceChart } from "./stats/BarCharts";
import Table from "./table/Table";
import FinanceFilters from "./FinanceFilters";
import { useWindowDimensions } from "../hooks/window";

const FinanceStats = () => {
  const { data, isLoading, error } = useFinance();

  const [loadedData, setLoadedData] = useState();
  const [chartData, setChartData] = useState([]);
  const [chartGroupBy, setChartGroupBy] = useState("month");
  const [currentFilters, setCurrentFilters] = useState([]);
  const { width } = useWindowDimensions();
  const [shortView, setShortView] = useState(false);

  useEffect(() => {
    if (!data) return;
    setLoadedData(data);
  }, [data]);

  useEffect(() => {
    setShortView(currentFilters.length > 0);
  }, [currentFilters]);

  useEffect(() => {
    if (!loadedData) return;

    const {
      orders_by_month,
      sales_volume_by_month,
      other_sales_volume_by_month,
    } = loadedData;
    if (!orders_by_month || !sales_volume_by_month) {
      setChartData([]);
      return;
    }

    const _chartData = [];
    for (let i = 0; i < orders_by_month.length; i++) {
      const month = orders_by_month[i].month;
      const orders = orders_by_month[i].count;
      const sales = sales_volume_by_month.find(
        (s) => s.order_month === month
      ).transaction_value;
      let otherSales = (other_sales_volume_by_month || []).find(
        (s) => s.order_month === month
      );
      otherSales = otherSales ? otherSales.transaction_value : 0;
      _chartData.push({
        month,
        orders,
        sales,
        otherSales,
        sumSales: sales + otherSales,
      });
    }
    if (chartGroupBy === "month") {
      setChartData(_chartData);
    } else {
      const __chartData = [];
      for (let i = 0; i < _chartData.length; i++) {
        const year = _chartData[i].month.split("-")[0];
        const orders = _chartData[i].orders;
        const sales = _chartData[i].sales;
        const otherSales = _chartData[i].otherSales;
        const sumSales = _chartData[i].sumSales;
        const index = __chartData.findIndex((d) => d.year === year);
        if (index === -1) {
          __chartData.push({
            year,
            orders,
            sales,
            otherSales,
            sumSales,
          });
        } else {
          __chartData[index].orders += orders;
          __chartData[index].sales += sales;
          __chartData[index].otherSales += otherSales;
          __chartData[index].sumSales += sumSales;
        }
        setChartData(__chartData);
      }
    }
  }, [loadedData, chartGroupBy]);

  const onFilter = (filters) => {
    if (!filters) {
      filters = currentFilters;
    } else {
      setCurrentFilters(filters);
    }
    directFinance(filters).then((_data) => {
      setLoadedData(_data);
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  let headers = financeTableHeaders;
  if (shortView) {
    headers = financeTableHeadersShort;
  }
  if (chartGroupBy === "year") {
    headers = financeTableHeadersYear;
    if (shortView) {
      headers = financeTableHeadersYearShort;
    }
  }

  if (!loadedData || loadedData.length === 0) {
    return <NoData />;
  }
  let warning = "";

  if (!loadedData.is_with_min_sales) {
    warning = (
      <div className="align-right">
        <span className="warning">
          Es werden mit diesen Filtern keine Mindestumsätze berücksichtigt!
        </span>
      </div>
    );
  }

  return (
    <>
      <FinanceFilters onFilter={onFilter} />
      <div className="align-right">
        <a
          href="/finance"
          onClick={(e) => {
            e.preventDefault();
            setChartGroupBy(chartGroupBy === "month" ? "year" : "month");
          }}
        >
          pro {chartGroupBy === "month" ? "Jahr" : "Monat"}
        </a>
      </div>
      {!shortView && (
        <FinanceChart
          data={chartData}
          width={Math.max(width - 200, 960)}
          barKey="orders"
          areaKey1="sales"
          areaKey2="otherSales"
          primaryDataKey={chartGroupBy}
          headers={headers}
        />
      )}
      {shortView && (
        <BarAreaChart
          data={chartData}
          width={Math.max(width - 200, 960)}
          barKey="orders"
          areaKey="sales"
          primaryDataKey={chartGroupBy}
          headers={headers}
        />
      )}
      <div className="align-right">
        <a
          href="/finance"
          onClick={(e) => {
            e.preventDefault();
            onFilter();
          }}
        >
          Daten neu laden
        </a>
      </div>
      {warning}
      <Table
        key={CryptoJS.SHA256(JSON.stringify(chartData)).toString()}
        _id="order-value-table"
        data={chartData}
        headers={headers}
        withSums={true}
      />
      {warning}
    </>
  );
};

export default FinanceStats;
