import React from "react";
import { H1 } from "./screen/Text";
import { MessageBoxSlim } from "./ExchangededDevices";

export const Error = ({ error }) => {
  return (
    <div className="pages">
      <H1>Fehler</H1>
      <br />
      <H1 id="defaultTypo" type="headline-2">
        {error.message}
      </H1>
    </div>
  );
};

export const Loading = ({ text = "Wird geladen..." } = {}) => (
  <div className="pages">
    <H1>{text}</H1>
    <br />
  </div>
);

export const NoData = () => (
  <div className="pages">
    <H1>Keine Daten</H1>
    <br />
  </div>
);

export const ErrorMessageBox = ({ error }) => {
  if (!error) return null;
  return (
    <>
      <MessageBoxSlim state="error" text={error} />
      <br />
      <br />
    </>
  );
};

export const SuccessMessageBox = ({ success }) => {
  if (!success) return null;
  return (
    <>
      <MessageBoxSlim state="info" text={success} />
      <br />
      <br />
    </>
  );
}
export const WarningMessageBox = ({ success }) => {
  if (!success) return null;
  return (
    <>
      <MessageBoxSlim state="warning" text={success} />
      <br />
      <br />
    </>
  );
}