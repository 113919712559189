import React from "react";
import { createContract } from "../hooks/useApi";
import { BdrButton } from "@style-guide/web-components-react";

import "./Contract.css";
import ContractForm from "../components/form/forms/ContractForm";
import { useNavigate } from "react-router-dom";

const NewContract = () => {
  const [contract, setContract] = React.useState({});
  const navigate = useNavigate();

  const reset = () => {
    navigate("/contracts");
  };
  const submit = async () => {

    const result = await createContract(contract);
    if (result.id) {
      navigate("/contracts/" + result.id);
    }
    // reset();
  };
  const Buttons = (
    <div className="button-group-right">
      <BdrButton
        onClick={reset}
        text="Abbrechen"
        type="button"
        variant="secondary"
      />
      <BdrButton onClick={submit} text="Speichern" type="submit" />
    </div>
  );

  return (
    <div className="pages device-card editable">
      {Buttons}
      <ContractForm
        contract={contract}
        editMode={true}
        setContract={setContract}
      />
      <pre>{contract.content}</pre>
    </div>
  );
};

export default NewContract;
