import React, { useEffect, useState } from "react";
import { useInfo } from "../hooks/useApi";

const Info = () => {
  const { data } = useInfo();
  const [version, setVersion] = useState("");
  useEffect(() => {
    if (data?.software) {
      setVersion(data.software);
    }
  }, [data]);
  return (
    <div>
      <p>Version: {version}</p>
      <p>Author: Hitabis GmbH</p>
    </div>
  );
};

export default Info;
