import React, { useEffect, useState } from "react";
import Card from "./Card";
import { useRepairStats } from "../../hooks/useApi";
import { RepairsByMonth } from "./BarCharts";
import InLine from "./InLine";
import { Error, Loading } from "../Helpers";
import { sortByKey } from "../../lib/sort";
import { DeviceLink, stringWithHashTagLinks } from "../../lib/links";
import Table from "../table/Table";
import PieChartOneValue from "./PieChartOneValue";

const RepairsStats = () => {
  const { data, isLoading, error } = useRepairStats();
  const [repairs, setRepairs] = useState(data?.repairs);

  useEffect(() => {
    if (
      data.most_repairs &&
      Array.isArray(data.most_repairs) &&
      data.most_repairs.length > 0
    ) {
      const sorted = sortByKey(data.most_repairs, "count", "DESC")
        .map(({ device_id, count, reasons, comments }) => ({
          device_id: <DeviceLink device_id={device_id} />,
          count,
          reasons: reasons?.filter((r) => r).join(", "),
          comments: comments?.filter((r) => r).join(", "),
        }))
        .map((repair) => ({
          ...repair,
          reasons: stringWithHashTagLinks(repair.reasons),
          comments: stringWithHashTagLinks(repair.comments),
        }));

      setRepairs(sorted);
    }
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }
  if (!data || !data.most_repairs) {
    return <Card title="Reparaturen">Keine Daten</Card>;
  }

  const totalReasons = data.reasons.reduce((acc, { count }) => acc + count, 0);

  return (
    <>
      <InLine>
        <div className="grid-rows">
          <RepairsByMonth repairs_by_month={data.repairs_by_month} />
        </div>

        <Card title="Reparaturgründe">
          <PieChartOneValue
            objects={data.reasons}
            width={400}
            height={150}
            showLabels={false}
          />
          <div className="center">
            {data.reasons.map(({ name, count }, index) => (
              <div className="grid-cols-3 hint-text" key={index}>
                <div>{((count / totalReasons) * 100).toFixed(2)}% </div>
                <div>{count}</div>
                <div>{stringWithHashTagLinks(name)}</div>
              </div>
            ))}
          </div>
        </Card>
      </InLine>
      <Card title="Geräte mit den meisten Reparaturen">
        {repairs && (
          <Table
            data={repairs}
            headers={[
              { id: "device_id", name: "SST" },
              { id: "count", name: "Count" },
              { id: "reasons", name: "Gründe" },
              { id: "comments", name: "Diagnosen" },
            ]}
          />
        )}
      </Card>
    </>
  );
};

export default RepairsStats;
