import React, { useEffect } from "react";
import { EditableField } from "../Field";
import { salesSearchMapping } from "../../../lib/constants/sales";

const SaleForm = ({ sale, setSale, editMode = true, onChange }) => {
  useEffect(() => {
    if (onChange) {
      onChange(sale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sale]);

  const editableField = (displayName, field, value, type = "input") => (
    <EditableField
      displayName={displayName}
      field={field}
      value={value}
      type={type}
      editMode={editMode}
      key={field}
      onChange={(e) => {
        setSale({ ...sale, [field]: e.target.value });
      }}
    />
  );

  const keyToType = (key) => {
    if (key === "net") {
      return "number";
    }
    if (key === "invoiced_at") {
      return "date";
    }
    return "input";
  };

  return (
    <>
      {Object.keys(salesSearchMapping).map((key) => {
        return editableField(
          salesSearchMapping[key],
          key,
          sale[key],
          keyToType(key)
        );
      })}
    </>
  );
};

export default SaleForm;
