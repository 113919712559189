import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { PrimeReactProvider } from "primereact/api";


import "./theme.css";
import "primereact/resources/primereact.min.css";
import "./App.css";

import Navbar from "./components/Navbar";
import Container from "./components/Container";
import Routes from "./Routes";

function App() {
  // prettier-ignore
  return (
    <PrimeReactProvider>
    <Router>
      <Navbar/>
      <div className="main-content">
        <Container>
          <Routes/>
        </Container>
        </div>
    </Router>
    </PrimeReactProvider>
  );
}

export default App;
