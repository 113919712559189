import React from "react";
import { useLocation } from "react-router-dom";
import { WIDE_PAGES } from "../Routes";


const Container = ({ children }) => {
  const location = useLocation();
  let currentPath = location.pathname;
  // strip ending "/" if it exists
  if (currentPath[currentPath.length - 1] === "/") {
    currentPath = currentPath.slice(0, -1);
  }
  const containerClass = !WIDE_PAGES.includes(currentPath)
    ? "container"
    : "max-width";
  return <div className={containerClass}>{children}</div>;
};
export default Container;
