import React, { useEffect, useState } from "react";
import { Error, Loading } from "../Helpers";
import { useDeviceOrders } from "../../hooks/useApi";
import Table from "../table/Table";
import { ordersByDeviceTableHeaders } from "../../lib/constants/orders";
import { sortByKey } from "../../lib/sort";

const DeviceOrders = ({ deviceId }) => {
  const { data, isLoading, error } = useDeviceOrders(deviceId);
  const [orders, setOrders] = useState(data?.orders);

  useEffect(() => {
    if (data?.orders) {
      setOrders(data.orders);
    }
  }, [data]);

  const sortBy = (key, sortDirection = "ASC") => {
    setOrders(sortByKey(orders, key, sortDirection));
  };

  if (isLoading || !orders) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  return (
    <Table data={orders} onSort={sortBy} headers={ordersByDeviceTableHeaders} />
  );
};

export default DeviceOrders;
