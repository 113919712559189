import React, { useState } from "react";

import "./Finance.css";
import {
  BdrTab,
  BdrTabContent,
  BdrTabHeader,
  BdrTabHeaderList,
} from "@style-guide/web-components-react";
import FinanceStats from "../components/FinanceStats";
import FinanceOrdersperDevice from "../components/FinanceOrdersperDevice";
import FinanceOrdersperPlace from "../components/FinanceOrdersperPlace";
import FinanceSales from "../components/FinanceSales";
import { useLocation } from "react-router-dom";

const Finance = () => {
  const location = useLocation();
  const tab = decodeURIComponent(location.hash.substring(1)) || "tab1";
  const [currentTab, setCurrentTab] = useState(tab);
  const [tabFilter, setTabFilter] = useState([]);
  const [tabYear, setTabYear] = useState(new Date().getFullYear());
  return (
    <div className="pages finance">
      <BdrTab
        key={`tab${currentTab}`}
        _id="tab_horizontal"
        default-active-tab-id={currentTab}
        orientation="horizontal"
        theme="white"
      >
        <BdrTabHeaderList>
          <BdrTabHeader _id="tab1" text="Übersicht" />
          <BdrTabHeader _id="tab2" text="Transaktionen pro SST" />
          <BdrTabHeader _id="tab3" text="Transaktionen je Standort" onTabChanged={()=>setCurrentTab("tab3")}/>
          <BdrTabHeader _id="sonstige" text="Sonstige Umsätze" />
        </BdrTabHeaderList>
        <BdrTabContent tab-header-id="tab1">
          <FinanceStats />
        </BdrTabContent>
        <BdrTabContent tab-header-id="tab2">
          <FinanceOrdersperDevice tabFilter={tabFilter} tabYear={tabYear}/>
        </BdrTabContent>
        <BdrTabContent tab-header-id="tab2">
          <FinanceOrdersperPlace
            setCurrentTab={setCurrentTab}
            setTabFilter={setTabFilter}
            tabYear={tabYear}
            setTabYear={setTabYear}
          />
        </BdrTabContent>
        <BdrTabContent tab-header-id="tab4">
          <FinanceSales />
        </BdrTabContent>
      </BdrTab>
    </div>
  );
};

export default Finance;
