import React from "react";
import { BdrTypo } from "@style-guide/web-components-react";

import { EditableField } from "../Field";

const ContractForm = ({ contract, editMode, setContract }) => {
  let {
    customer,
    state,
    description,
    department,
    signed_at,
    comment,
    customer_id,
    number_of_devices,
    locations,
  } = contract;


  const editableField = (displayName, field, value, type = "input", readOnly=false) => (
    <EditableField
      displayName={displayName}
      field={field}
      value={value}
      type={type}
      editMode={readOnly ? false : editMode}
      onChange={(e) => {
        console.log("onChange", field, e.target.value);
        setContract({ ...contract, [field]: e.target.value });
      }}
    />
  );

  console.log("contract", contract);

  return (
    <>
      {editableField("Kunde", "customer", customer, undefined, true)}
      {editableField("Beschreibung", "description", description, undefined, true)}

      <div className="table-row">
        <div className="table-cell pr-4">
          <BdrTypo type="body-medium">Status</BdrTypo>
        </div>
        <div className="table-cell">
          {state}
        </div>
      </div>
      {editableField(
        "Vertragsunterschrift",
        "signed_at",
        signed_at,
        "date",
        true
      )}
      {editableField(
        "Anzahl Geräte",
        "number_of_devices",
        number_of_devices,
        "number",
        true
      )}
      {editableField("Kundennummer", "customer_id", customer_id)}
      {editableField("Abteilung", "department", department, "textarea")}
      {editableField("Kommentar", "comment", comment, "textarea")}
      {editableField("Standorte", "locations", locations, "textarea")}
    </>
  );
};

export default ContractForm;
