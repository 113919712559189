import React, { useEffect, useState } from "react";
import { useContracts } from "../../hooks/useApi";
import { Error, Loading } from "../Helpers";
import Card from "./Card";
import Table from "../table/Table";
import { contractStates, contractsByStateHeaders } from "../../lib/constants/contracts";

const ContractsByState = () => {
  const { data, isLoading, error } = useContracts();
  const [contracts, setContracts] = useState([]);
  

  useEffect(() => {
    if(!data || !data.contracts) return
    const _data= []
    contractStates.forEach((state) => {
        _data.push({
            state: state,
            count: data.contracts.filter((contract) => contract.state === state).length,
            number_of_devices: data.contracts.filter((contract) => contract.state === state).reduce((acc, contract) => acc + contract.number_of_devices, 0),
        });
        });
    setContracts(_data);
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }
  return (
    <>
      <Card title="">
        {contracts && (
          <Table data={contracts} headers={contractsByStateHeaders} footers={[]} />
        )}
      </Card>
    </>
  );
};

export default ContractsByState;
