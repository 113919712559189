import { BdrButton } from "@style-guide/web-components-react";
import React from "react";

const Button = ({
  text,
  onClick,
  type = "button",
  variant = "primary",
  children,
  disabled = false,
  small = false,
}) => (
  <BdrButton
    disabled={disabled}
    onClick={onClick}
    text={text || children}
    type={type}
    variant={variant}
    size={small ? "small" : "medium"}
    _id="button"
  />
);

export default Button;
