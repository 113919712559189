import { useState, useEffect } from "react";

import BASE_URL from "./url";
import { getToken, removeToken } from "../lib/token";
import errorcodes from "../lib/constants/errorcodes";

const useApi = (path = "", preLoadedData = {}, method = "GET", body) => {
  const [data, setData] = useState(preLoadedData);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    if (Object.keys(data).length === 0) {
      setIsLoading(true);

      doApiCall(path, method, body)
        .then((data) => {
          setData(data);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log("error", JSON.stringify(e));
          setError(e);
          setIsLoading(false);
        });
    }
  }, [data, path, method, body]);
  return { data, isLoading, error };
};

const doApiCall = async (path, method, body, contentType) => {
  const headers = {
    Authorization: getToken(),
  };
  if (!contentType) {
    headers["Content-Type"] = "application/json";
  }
  let url = `${BASE_URL}${path}`;
  if (path === "login") {
    url = `${BASE_URL.replace("/frontend", "")}${path}`;
  }
  const response = await fetch(url, {
    method,
    headers,
    body: contentType === "multipart/form-data" ? body : JSON.stringify(body),
  });
  if (!response.ok) {
    await handleErrorResponse(response);
  }
  return response.json();
};

const handleErrorResponse = async (response) => {
  const responseText = await response.clone().text();
  if (responseText.includes("errorCode") && !is401(response)) {
    const responseBody = await response.clone().json();
    console.error(
      "responseBody",
      responseBody,
      errorcodes[responseBody.errorCode]
    );
    if (responseBody?.errorCode && errorcodes[responseBody.errorCode]) {
      throw new Error(JSON.stringify(responseBody));
    } else {
      throw new Error(response.statusText);
    }
  }
  handleUnauthorized(response);
};

const is401 = (response) => response.status === 401;

const handleUnauthorized = (e) => {
  if (is401(e)) {
    removeToken();
    window.location.reload();
  }
};

export default useApi;

export const useDevices = () => useApi(`devices`);
export const useDevice = (id) => useApi(`devices/${id}`);
export const useDeviceOrders = (id) => useApi(`devices/${id}/orders`);
export const useDeviceMovements = (id) => useApi(`devices/${id}/movements`);
export const useRepairs = () => useApi("repairs");
export const useStats = () => useApi("stats");
export const useRepairStats = () => useApi("stats/repairs");
export const useOrderStats = (year) => useApi("stats/orders/" + year);
export const useMapStats = () => useApi("stats/maps");
export const useVersionStats = () => useApi("stats/versions");
export const useExchangeRateStats = () => useApi("stats/exchange_rate");
export const useFinance = () => useApi("orders");
export const useFinanceFilters = () => useApi("orders/filters");
export const useMixedUps = () => useApi("mixedups");
export const useActiveMixedUps = () => useApi("mixedups?active=true");
export const useConflict = (id) => useApi(`mixedups/${id}`);
export const useContracts = () => useApi("contracts");
export const useContract = (hash) => useApi(`contracts/${hash}`);
export const useExchanges = () => useApi("exchanges");
export const useRules = () => useApi("rules");
export const usePlainRules = () => useApi("rules/plain");
export const useRule = (id) => useApi(`rules/${id}`);
export const useInfo = () => useApi("info");

export const useAllData = () => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    if (Object.keys(data).length === 0) {
      setIsLoading(true);

      const API_PATHS = ["devices", "repairs"];
      Promise.all(API_PATHS.map((path) => doApiCall(path, "GET")))
        .then((responses) => {
          const [devices, repairs] = responses;
          setData({ devices: devices.devices, repairs: repairs.repairs });
          setIsLoading(false);
        })
        .catch((e) => {
          setError(e);
          setIsLoading(false);
        });
    }
  }, [data]);
  return { data, isLoading, error };
};

export const login = (username, password) =>
  doApiCall("login", "POST", { username, password });

// Post [file] to /upload oder path
export const UploadDirect = (files, path = "upload") => {
  const formData = new FormData();
  files.forEach((file, index) => {
    formData.append(`file`, file);
  });
  return doApiCall(path, "POST", formData, "multipart/form-data");
};

export const directDevices = (first) =>
  doApiCall(first ? `devices?first=${first}` : "devices");

export const directFinance = (data) => doApiCall("orders", "POST", data);
export const directOrderStats = (year, groupBy) =>
  doApiCall(`stats/orders/${year}?${groupBy ? `groupBy=${groupBy}` : ""}`);

export const changeContract = (contract) =>
  doApiCall(`contracts/${contract.id}`, "PUT", contract);
export const createContract = (contract) =>
  doApiCall(`contracts`, "POST", contract);
export const deleteContract = (contract) =>
  doApiCall(`contracts/${contract.id}`, "DELETE", contract);
export const directContract = (contract) =>
  doApiCall(`contracts/${contract.id}`);

export const createExchange = (exchange) =>
  doApiCall(`exchanges`, "POST", exchange);
export const deleteExchange = (exchange) =>
  doApiCall(`exchanges/${exchange.id}`, "DELETE", exchange);
export const directExchanges = (year) => doApiCall(`exchanges?year=${year}`);
export const deleteContractAttachment = (attachment) =>
  doApiCall(
    `contracts/${attachment.contract_hash}/upload/${attachment.id}`,
    "DELETE",
    attachment
  );

export const changeConflict = (conflict) =>
  doApiCall(`conflicts/${conflict.id}`, "PUT", conflict);

export const createRule = (rule) => doApiCall(`rules`, "POST", rule);
export const deleteRule = (rule) =>
  doApiCall(`rules/${rule.id}`, "DELETE", rule);
export const directRules = (first) => doApiCall(`rules`);
export const updateRule = (rule) => doApiCall(`rules/${rule.id}`, "PUT", rule);

export const useUsers = () => useApi("users");
export const deleteUser = (user) =>
  doApiCall(`users/${user.id}`, "DELETE", user);
export const updateUser = (user) => doApiCall(`users/${user.id}`, "PUT", user);
export const createUser = (user) => doApiCall(`users`, "POST", user);
export const resetUserPassword = (user) =>
  doApiCall(`users/${user.id}/reset`, "POST", {});
export const resetMyPassword = (password, old_password) =>
  doApiCall(`users/reset_password`, "POST", { password, old_password });
export const useUploadHistory = () => useApi("upload/history");
export const directMixedUps = () => doApiCall("mixedups");

export const useSales = () => useApi("sales");
export const useSale = (id) => useApi(`sales/${id}`);
export const updateSale = (id,sales) => doApiCall(`sales/${id}`, "PUT", sales);
export const createSale = (sales) => doApiCall("sales", "POST", sales);
export const deleteSale = (id) => doApiCall(`sales/${id}`, "DELETE", id);
