import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { deleteSale, updateSale, useSale } from "../hooks/useApi";
import {
  Error,
  ErrorMessageBox,
  Loading,
  WarningMessageBox,
} from "../components/Helpers";
import { EditButtonGroup } from "../components/form/Editbuttons";
import SaleForm from "../components/form/forms/SaleForm";
import { handleErrorResponse } from "../lib/errors";
import { salesLink } from "../lib/links";

const EditSale = () => {
  let { id } = useParams();
  const { data, error, isLoading } = useSale(id);
  const [sale, setSale] = useState({});
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState(false);
  const [successText, setSuccessText] = useState(false);

  useEffect(() => {
    if (data && data.sale) {
      setSale(data.sale);
    }
  }, [data, id]);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  const reset = () => {
    setSale(data.sale);
    setEditMode(false);
    // navigate(usersLink);
  };

  const submit = async () => {
    setSuccessText(false);
    setErrorText(false);
    try {
      await updateSale(id,sale);
      console.log("success updateSale",salesLink);
      navigate(salesLink);
    } catch (error) {
      console.log("error updateSale", error);
      const errorMessage = handleErrorResponse(error.message);
      setErrorText(errorMessage);
    }
  };
  const remove = async () => {
    setSuccessText(false);
    setErrorText(false);
    const isOK = window.confirm("Sonstigen Umsatz löschen?");
    if (!isOK) return;
    try {
      await deleteSale(id);
      navigate(salesLink);
    } catch (error) {
      const errorMessage = handleErrorResponse(error.message);
      setErrorText(errorMessage);
    }
  };

  return (
    <div className="pages device-card editable">
      {<WarningMessageBox success={successText} />}
      {<ErrorMessageBox error={errorText} />}
      <EditButtonGroup
        editMode={editMode}
        setEditMode={setEditMode}
        cancel={reset}
        save={submit}
        del={remove}
      />
      <SaleForm sale={sale} setSale={setSale} editMode={editMode} />
    </div>
  );
};
export default EditSale;
