import { germanDay } from "../date";
import { deviceLinkFormatter } from "../links";

export const devicesTableHeaders = [
  {
    id: "serial_number",
    name: "Seriennummer SST",
    customFormatter: deviceLinkFormatter,
  },
  { id: "hardware_version", name: "Version", customFormatter: (value) => value },
  { id: "simago_version", name: "Version Simago" },
  { id: "hd_client_version", name: "Version HDClient" },
  { id: "state", name: "Status/Ort", customFormatter: (value) => 
  ['Bereit zur Auslieferung','In Reparatur','Verschrottet'].includes(value) ? <i>{value}</i> : value 
  } ,
];

export const devicesTableHeadersLong = [
  { id: "serial_number", name: "Seriennummer SST" },
  { id: "simago_version", name: "Version Simago" },
  { id: "hd_client_version", name: "Version HDClient" },
  // { id: "comment", name: "Bemerkung" },
  { id: "contract_type", name: "Vertragsart" },
  // { id: "pc", name: "PC" },
  // { id: "display", name: "Display" },
  // { id: "fingerprint_left", name: "Fingerprint Links" },
  // { id: "fingerprint_right", name: "Fingerprint Rechts" },
  // { id: "card_reader", name: "Ausweisleser" },
  // { id: "camera", name: "Kamera" },
  // { id: "lens", name: "Objektiv" },
  // { id: "light_control", name: "Lichtsteuerung" },
  // { id: "pillar", name: "Säule" },
  // { id: "pillar_control", name: "Säulensteuerung" },
  // { id: "transformer24", name: "Trafo 24V" },
  // { id: "transformer19", name: "Trafo 19V" },
  // { id: "usb_hub", name: "USB-Hub" },
  // { id: "key", name: "Schluessel" },
  // { id: "sound", name: "Lautsprecher" },
  // { id: "image", name: "Image" },
  // { id: "hardware_version", name: "Hardwareversion" },
  // { id: "mac_address", name: "MAC-Adresse" },
  // { id: "voltage_converter12", name: "Spannungswandler 12V" },
];

export const deviceMovementsHeaders = [
  { id: "date", name: "Datum", customFormatter: germanDay },
  { id: "moved", name: "" },
  { id: "position", name: "Standort" },
];
