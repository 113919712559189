import React, { useState } from "react";
import { useStats } from "../hooks/useApi";
import TopRow from "../components/stats/TopRow";
import { AllMaps } from "../components/stats/Map";
import { DevicesByMonth, OrdersByMonth } from "../components/stats/BarCharts";
import Details from "../components/stats/Details";
import { Error, Loading } from "../components/Helpers";

import "./Stats.css";

const Stats = () => {
  const { data, isLoading, error } = useStats();

  const [openDetail, setOpenDetail] = useState(null);

  const changeOpenDetail = (field) => {
    openDetail === field ? setOpenDetail(null) : setOpenDetail(field);
  };

  // const { data, isLoading, error } = useAllData();
  return (
    <div className="stats-page">
      {isLoading && <Loading />}
      {error && <Error error={error} />}
      {data && data.totals && (
        <TopRow
          {...data}
          setOpenDetail={changeOpenDetail}
          openDetail={openDetail}
        />
      )}

      <Details {...data} selected={openDetail} />

      {data && data.orders_by_month && (
        <OrdersByMonth data={data.orders_by_month} />
      )}
      {data && data.devices_by_month && (
        <DevicesByMonth data={data.devices_by_month.slice(-12)} />
      )}

      <AllMaps {...data} />
    </div>
  );
};

export default Stats;
