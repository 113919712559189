import React, { useEffect, useState } from "react";
import { useRules } from "../hooks/useApi";
import { Error, Loading } from "./Helpers";
import { Link } from "react-router-dom";
import Button from "./form/Button";
import Table from "./table/Table";
import { rulesHeaders } from "../lib/constants/rules";
import { dateIsAfter } from "../lib/date";
import { DevicesFilterLink } from "../lib/links";

const Rules = () => {
  const { data, error, isLoading } = useRules();
  const [rules, setRules] = useState(data?.rules);

  const handleRules = (rules) =>
    rules.map((rule) => {
      rule.positive_number_of_devices = DevicesFilterLink(
        rule.positive_number_of_devices,
        [`rule_${rule.id}:Zutreffend`]
      );
      rule.negative_number_of_devices = DevicesFilterLink(
        rule.negative_number_of_devices,
        [`rule_${rule.id}:Nicht zutreffend`]
      );
      rule.name = <Link to={`/settings/rules/${rule.id}`}>{rule.name}</Link>;
      return rule;
    });

  useEffect(() => {
    if (data?.rules) {
      setRules(handleRules(data.rules));
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }
  return (
    <>
      <div className="button-group-right">
        <Link to="/settings/rules/new">
          <Button text="Neue Regel" type="button" variant="secondary" />
        </Link>
        <br />
      </div>
      <Table headers={rulesHeaders} data={rules} />
    </>
  );
};

export default Rules;

export const ruleIsValidForDevice = (rule, device) => {
  if (!deviceHasOneOfVersion(rule.affected_versions, device)) {
    return "-";
  }
  return dateIsAfter(device.last_moved_out, rule.valid_from)
    ? "Zutreffend"
    : "Nicht zutreffend";
};

const deviceHasOneOfVersion = (version, device) =>
  device.hardware_version &&
  version &&
  version.filter((v) => device.hardware_version === v).length > 0;
