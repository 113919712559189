import L from "leaflet";
import React from "react";
import { createControlComponent } from "@react-leaflet/core";
import { createRoot } from "react-dom/client";

const legendStyle = {
  backgroundColor: "white",
  padding: "10px",
  borderRadius: "5px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
  textAlign: "left",
};
const LegendCount = ({ ranges }) => {
  return (
    <div style={legendStyle}>
      {ranges &&
        ranges.map(({ icon, label }) => (
          <p key={label}>
            <img
              src={icon.options.iconUrl}
              alt={label}
              style={{
                height: "10px",
                marginRight: "5px",
                display: "inline-block",
              }}
            />
            {label}
          </p>
        ))}
    </div>
  );
};

const LegendColor = ({ ranges }) => {
  return (
    <div style={legendStyle}>
      {ranges &&
        ranges.map(({ color, label }) => (
          <p key={label}>
            <span
              style={{
                backgroundColor: color,
                width: "10px",
                height: "10px",
                marginRight: "5px",
                display: "inline-block",
              }}
            />
            {label}
          </p>
        ))}
    </div>
  );
};

const LegendControl = (LegendComponent) =>
  createControlComponent((props) => {
    const legend = L.control({ position: "bottomright" });
    legend.onAdd = () => {
      const div = L.DomUtil.create("div", "info legend");
      createRoot(div).render(<LegendComponent {...props} />);
      return div;
    };

    return legend;
  });

export const LegendCountControl = LegendControl(LegendCount);
export const LegendColorControl = LegendControl(LegendColor);
